import axios from 'axios';
import { stringify } from 'query-string';
import { authCallbackUrl, authBaseUrl, adminMode } from '../../util/constants';

export default async function authenticate(authCode: string, redirect_uri: string = authCallbackUrl): Promise<void> {
  const queryParams = stringify({
    authorization_code: authCode,
    grant_type: 'authorization_code',
    redirect_uri,
  });
  const {
    data,
  } = await axios.post(`${authBaseUrl}/login?${queryParams}`, {
    admin: !!adminMode,
  }, {
    withCredentials: true,
  });
  return data.userId;
}
