import callFetchCreditAccounts from '../api/fetchCreditAccounts';
import { setState, callApi } from '../../util';

export default function* fetchCreditAccounts() {
  const {
    creditAccounts,
  } = yield callApi('creditAccounts/get', callFetchCreditAccounts);

  yield setState('creditAccounts', (state) => ({
    ...state,
    ...creditAccounts,
  }));
}
