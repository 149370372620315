import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { creditBaseUrl } from '../../util/constants';

export default async function fetchExcel(startDate: string, endDate: string, organizationId: string) {
  const qs = stringify({
    startDate,
    endDate,
    organizationId,
    reportFormat: 'xlsx',
  });

  const {
    data,
  } : AxiosResponse = await axios.get(`${creditBaseUrl}/getTransactionsReport?${qs}`, {
    responseType: 'blob',
    withCredentials: true,
  });

  return data;
}
