import 'redux-saga';
import callFetchUser from '../api/fetchUser';
import { setState, callApi } from '../../util';

export default function* fetchUser() {
  // @ts-ignore
  const user = yield callApi('user/get', callFetchUser);
  yield setState('user', (state) => ({
    ...state,
    ...user,
  }));
}
