import { callApi } from '../../util';
import callFetchExcel from '../api/fetchExcel';
import { selectActiveOrgId } from '../../organization/selectors';
import type { ResponseData } from '../types';

type P = {
  startDate: string;
  endDate: string;
};

export default function* fetchExcel({
  startDate,
  endDate,
}: P): ResponseData {
  return yield callApi('order/excel', callFetchExcel, startDate, endDate, selectActiveOrgId());
}
