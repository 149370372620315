import axios from 'axios';
import { creditBaseUrl } from '../../util/constants';

export default async function updateCreditAccount(organizationId: string, updatedFields: {
  alertAddresses: string[];
  alertThreshold: number;
}): Promise<any> {
  const {
    data,
  } = await axios.put(`${creditBaseUrl}/ModifyCreditAccount`, {
    organizationId,
    ...updatedFields,
  }, {
    withCredentials: true,
  });
  return data;
}
