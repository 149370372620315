import axios from 'axios';
import { authBaseUrl } from '../../util/constants';

export default function acceptInvite(inviteToken: string) {
  return axios.put(`${authBaseUrl}/acceptInvite`, {
    inviteToken,
  }, {
    withCredentials: true,
  });
}
