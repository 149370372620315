import { Grid } from 'semantic-ui-react';

const NotFound = () => (
  <Grid container stretched>
    <Grid.Row>
      <Grid.Column mobile={16} tablet={16} computer={16}>
        <p>404 page - route not found</p>
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

export default NotFound;
