import { Organization, PendingInvite } from '../types';
import callInviteUser from '../api/inviteUsers';
import { callApi, setState } from '../../util';
import { selectActiveOrgId } from '../selectors';

export default function* inviteUsers(invitees: string[]) {
  const organizationId = selectActiveOrgId();
  const pendingInvites: PendingInvite[] = yield callApi('user/inviteusers', callInviteUser, invitees, organizationId);
  yield setState(['organizations', organizationId], (org: Organization) => {
    const organization = { ...org };

    pendingInvites.forEach((invite: PendingInvite) => {
      organization.pendingInvites?.push(invite);
    });

    return organization;
  });
}
