import { AuthNamespaceState } from './types';

export const getAuthStateFromStorage = (): AuthNamespaceState => {
  const loggedIn = localStorage.getItem('loggedIn');
  const isAdmin = localStorage.getItem('isAdmin');
  const userId = localStorage.getItem('userId');

  return {
    loggedIn: loggedIn === 'true',
    isAdmin: isAdmin === 'true',
    userId: userId !== ''
      ? userId
      : null,
  };
};

export const setAuthStateToStorage = ({ loggedIn, isAdmin, userId }: AuthNamespaceState) => {
  localStorage.setItem('loggedIn', String(loggedIn));
  localStorage.setItem('isAdmin', String(isAdmin));
  if (userId) {
    localStorage.setItem('userId', userId);
  } else {
    localStorage.removeItem('userId');
  }
};
