import { Organization } from '../types';
import callRemoveUser from '../api/removeUser';
import { callApi, setState } from '../../util';
import { selectActiveOrgId } from '../selectors';

export default function* removeUser(user: {
  id: string;
}) {
  const body = {
    organizationId: selectActiveOrgId(),
    userId: user.id,
  };
  yield callApi('user/remove::SILENT', callRemoveUser, body);
  yield setState(['organizations', body.organizationId], (organization: Organization) => {
    // Remove the user
    const users = organization.users.filter(({
      id,
    }) => id !== user.id);
    return {
      ...organization,
      users,
    };
  });
}
