import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { selectOrganization, selectActiveOrgId } from '../selectors';
import OrganizationForm from './OrganizationForm';
import { runSaga } from '../../util';
import fetchOrganization from '../sagas/fetchOrganization';
import type { Organization } from '../types';
import { isFetching } from '../../requests/selectors';
import ApiKeyList from './ApiKeyList';
import Navbar from '../../app/components/Navbar';
import LoaderOverlay from '../../app/components/LoaderOverlay';
import UserList from './UserList';
import Invites from './InvitesList';
import type { StoreState } from '../../state/types';

type Props = {
  organization: Organization | null | undefined;
  isFetching: boolean;
};

class OrganizationPage extends Component<Props, {}> {
  componentDidMount() {
    if (!this.props.organization) {
      runSaga(fetchOrganization, selectActiveOrgId());
    }
  }

  render() {
    return (
      <main>
        <Container>
          <LoaderOverlay active={this.props.isFetching} />
          <Navbar />
          <Grid container centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h1>Organization Information</h1>
                { /* @ts-ignore */}
                <OrganizationForm initialValues={this.props.organization} enableReinitialize />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <GrayBlock>
            <UserList />
            <Invites />
          </GrayBlock>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <ApiKeyList />
            </Grid.Column>
          </Grid.Row>
        </Container>
      </main>
    );
  }
}

export default compose(connect((state: StoreState): any => ({
  organization: selectOrganization(state, selectActiveOrgId() || ''),
  isFetching: isFetching(state, 'organization/get'),
})))(OrganizationPage);

const Container = styled.div`
  width: 100%;
`;

const GrayBlock = styled.div`
  background-color: #f8f8f8;
  padding-bottom: 20px;
  padding-top: 13px;
  margin-bottom: 35px;
`;
