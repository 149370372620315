/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, SyntheticEvent } from 'react';
import type { InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { Field, SubmissionError } from 'redux-form';
import { Grid, Dropdown, DropdownProps } from 'semantic-ui-react';
import styled from 'styled-components';
import InputField from '../../forms/components/InputField';
import TextareaField from '../../forms/components/TextareaField';
import {
  required, minLength1, minLength4, minLength2, validateEmail,
} from '../../forms/helpers';
import Toast from '../../app/components/Toast';
import { asForm, runSaga } from '../../util';
import HslButton from '../../forms/components/HslButton';
import { selectIsAdmin } from '../../auth/selectors';
import SelectorWrapper from '../../auth/wrappers/SelectorWrapper';
import updateOrganization from '../sagas/updateOrganization';
import { selectActiveOrgId } from '../selectors';
import type { Organization } from '../types';

const contractApiOptions = [{
  key: 'public',
  text: 'Public OpenMaaS API',
  value: 'public',
}, {
  key: 'partner',
  text: 'Partner API',
  value: 'partner',
}];

const renderMultiSelectDropdown = (props: WrappedFieldProps) => {
  const onChange = (event: SyntheticEvent<HTMLElement, Event>, data: DropdownProps) => props.input.onChange(data.value);

  // @ts-ignore
  return <Dropdown multiple selection options={contractApiOptions} {...props.input} onChange={onChange} />;
};

const OrganizationDetails = SelectorWrapper(selectIsAdmin, (
  <Grid.Row>
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <Field label="Details (admin only)" id="details" name="details" component={TextareaField} type="textarea" />
    </Grid.Column>
  </Grid.Row>
));

const ContractApis = SelectorWrapper(selectIsAdmin, (
  <Grid.Row>
    <Grid.Column mobile={16} tablet={16} computer={16}>
      <p>Contract APIs</p>
      <Field name="contractApis" component={renderMultiSelectDropdown} />
    </Grid.Column>
  </Grid.Row>
));

type Props = {
  submitErrors: any;
  initWithValues: () => any;
  fieldValues: any;
};

class OrganizationForm extends Component<InjectedFormProps & Props, {}> {
  submitEdit = async ({
    id,
    name,
    sandboxApiKey,
    users,
    pendingInvites,
    customerServiceEmail: csE,
    customerServicePhone: csP,
    ...body
  }: Organization) => {
    const reqBody = {
      ...body,
      customerServiceEmail: csE || '',
      customerServicePhone: csP || '',
      contractApis: this.props.fieldValues.contractApis,
    };
    // console.log(reqBody.contractApis);

    // delete reqBody.contractApis
    try {
      await runSaga(updateOrganization, selectActiveOrgId(), reqBody);
    } catch (e) {
      throw new SubmissionError({
        general: 'Submission failed!',
      });
    }
  };

  render() {
    const {
      handleSubmit,
      invalid,
      submitting,
      submitErrors,
      initWithValues,
      submitFailed,
      submitSucceeded,
    } = this.props;
    return /* @ts-ignore */(
      <form onSubmit={handleSubmit(this.submitEdit)}>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field disabled label="Name" id="name" name="name" component={InputField} validate={[required, minLength2]} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field label="Application or Service name to be shown on ticket" id="appName" name="appName" component={InputField} validate={[required, minLength1]} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field label="Organization phone for HSL use" id="orgphone" type="tel" name="organizationPhone" component={InputField} validate={[required, minLength4]} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field disabled label="Organization Id" id="id" name="id" component={InputField} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field label="Customer service email" id="Customer service email" type="email" name="customerServiceEmail" component={InputField} validate={validateEmail} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field label="Organization email for HSL use" id="orgemail" type="email" name="organizationEmail" component={InputField} validate={[validateEmail, required]} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <Field label="Customer service phone" type="tel" id="Customer service phone" name="customerServicePhone" component={InputField} validate={minLength4} />
            </Grid.Column>
          </Grid.Row>
          <OrganizationDetails />
          <ContractApis />
          <SubmitButtonRow>
            <Grid.Column mobile={16} tablet={16} computer={8}>
              <HslButton loading={submitting} disabled={submitting || invalid} type="submit">
                Submit
              </HslButton>
            </Grid.Column>
          </SubmitButtonRow>
        </Grid>
        <Toast error={submitFailed} message={submitErrors.general || (submitSucceeded && 'Updated')} onClose={initWithValues} />
      </form>
    );
  }
}

export default asForm('organizationForm')(OrganizationForm);

const SubmitButtonRow = styled(Grid.Row)`
  margin-bottom: 20px;
`;
