/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
import { sortBy } from 'lodash';
import React, { FC, useEffect } from 'react';
import {
  Table,
  TableBodyProps,
  TableHeaderCellProps,
} from 'semantic-ui-react';
import styled from 'styled-components';
import InputField from '../../forms/components/InputField';
import { AdminOrganization } from '../../organization/types';

type TableDataRequired = {
  id: string;
};

type TableDataDynamic = Partial<Record<string, any>>;

type TableData = TableDataRequired & TableDataDynamic;

type TableColumn = TableHeaderCellProps & {
  id: string,
  header: string,
  accessor: string,
};

type Props = TableBodyProps & {
  columns: TableColumn[],
  data: TableData[],
  onRowClick?: (id: string) => unknown,
};

const initState = (tableData?: TableData[]) => ({
  tableData,
  sortColumn: null,
  sortDirection: null,
  searchKeyword: '',
});

// TODO: asof this ties organizations to this table component
const sortOrganizationToTable = (organizations: AdminOrganization[], sortColumn: string) => {
  let newData;
  switch (sortColumn.toLowerCase()) {
    case 'name':
      newData = sortBy(organizations, [(organization: AdminOrganization) => organization.name.toLowerCase()]);
      break;
    case 'id'.toLowerCase():
      newData = sortBy(organizations, [(organization: AdminOrganization) => organization.id.toLowerCase()]);
      break;
    case 'hslcustomerid'.toLowerCase():
      newData = sortBy(organizations, [(organization: AdminOrganization) => (organization.hslCustomerId ? Number(organization.hslCustomerId) : 0)]); // TIP: set 0 to 999999 if you want empty as last
      break;
    case 'referencecode'.toLowerCase():
      newData = sortBy(organizations, [(organization: AdminOrganization) => (organization.referenceCode ? Number(organization.referenceCode) : 0)]);
      break;
    case 'createdatDisplay'.toLowerCase():
      newData = organizations.sort((org1, org2) => new Date(org2.createdAt as string).getTime() - new Date(org1.createdAt as string).getTime());
      break;
    case 'createdby'.toLowerCase():
      newData = sortBy(organizations, [(organization: AdminOrganization) => organization.createdBy?.toLowerCase()]);
      break;
    case 'updatedatDisplay'.toLowerCase():
      newData = sortBy(organizations, [(organization: AdminOrganization) => (organization.updatedAt ? new Date(organization.updatedAt).valueOf() : 0)]);
      break;
    case 'updatedby'.toLowerCase():
      newData = sortBy(organizations, [(organization: AdminOrganization) => organization.updatedBy?.toLowerCase()]);
      break;
    default:
      newData = sortBy(organizations, [sortColumn]);
      break;
  }
  return newData;
};

const applyTransformers = (state: any, data: TableData[], columns: TableColumn[]) => {
  let newData = data;
  if (state.searchKeyword) {
    const regexp = new RegExp(state.searchKeyword, 'i');
    newData = newData.filter((item: any) => {
      let found = false;
      for (const col of columns) {
        if (item[col.accessor]?.match(regexp)) {
          found = true;
        }
      }
      return found ? item : null;
    });
  }
  if (state.sortColumn) {
    newData = sortOrganizationToTable(newData as AdminOrganization[], state.sortColumn); // sortBy(newData, [state.sortColumn]);
    if (state.sortDirection === 'descending') {
      newData = newData.reverse();
    }
  }
  return newData;
};

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case 'DATA_CHANGE':
      return initState(action.data);
    case 'CHANGE_SORT': {
      const newState = {
        ...state,
        sortColumn: action.column,
        sortDirection: (state.sortDirection === 'ascending') ? 'descending' : 'ascending',
      };
      return {
        ...newState,
        tableData: applyTransformers(newState, state.tableData, action.columns),
      };
    }
    case 'CHANGE_SEARCH': {
      const newState = {
        ...state,
        searchKeyword: action.search,
      };
      return {
        ...newState,
        tableData: applyTransformers(newState, action.data, action.columns),
      };
    }
    default:
      throw new Error();
  }
};

export const CustomTable: FC<Props> = ({
  data, columns, onRowClick, ...tableBodyProps
}) => {
  const [state, dispatch] = React.useReducer(reducer, data, initState);
  const {
    sortColumn,
    sortDirection,
    tableData,
  } = state;

  useEffect(() => {
    dispatch({ type: 'DATA_CHANGE', data });
  }, [dispatch, data]);

  const handleRowClick = (id: string) => () => {
    if (onRowClick) onRowClick(id);
  };

  return (
    <>
      <div>
        <InputField
          id="organization-search-input"
          input={{
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => dispatch({
              type: 'CHANGE_SEARCH',
              data,
              columns,
              search: e.target.value,
            }),
          }}
          placeholder="Free text search"
        />
      </div>

      <Table sortable celled fixed selectable {...tableBodyProps}>

        <Table.Header>
          <Table.Row>
            {columns.map(({ id, header, ...tableHeaderCellProps }) => (
              <Table.HeaderCell
                key={id}
                sorted={sortColumn === id ? sortDirection : null}
                onClick={() => dispatch({ type: 'CHANGE_SORT', columns, column: id })}
                {...tableHeaderCellProps}
              >
                { header }
              </Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tableData.map((d: TableData) => (
            <TableRow key={d.id} onClick={handleRowClick(d.id)}>
              {columns.map(({ id, accessor }) => (
                <Table.Cell onClick={handleRowClick(d.id)} key={id}>{d[accessor]}</Table.Cell>
              ))}
            </TableRow>
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={columns.length}>Showing {tableData.length} of {data.length} organizations</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>

      </Table>
    </>
  );
};

export default CustomTable;

const TableRow = styled(Table.Row)`
  &:hover {
    cursor: pointer;
  }
`;
