import { callApi, setState } from '../../util';
import callConfirmCard from '../api/confirmCard';
import { Card, CardsNameSpaceState } from '../types';

export default function* confirmCard(id: string) {
  const card: Card = yield callApi(`card/confirm/${id}`, callConfirmCard, id);
  yield setState('cards', (cards: CardsNameSpaceState): CardsNameSpaceState => ({
    ...cards,
    [card.id]: card,
  }));
}
