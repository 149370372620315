import { call } from 'redux-saga/effects';
import { callApi, setState } from '../../util';
import callUpdateOrganization from '../api/updateOrganization';
import { Organization, UpdateOrganizationBody } from '../types';
/**
 *
 * @param {*} orgId
 * @param {*} body
 * @param {*} trackRequest Flag for explicitly tracking the request. Needed when not invoked via redux form's submit.
 */

export default function* updateOrganization(orgId: string, body: UpdateOrganizationBody, trackRequest?: boolean) {
  let updatedOrg: Record<string, Organization> | {} = {};
  const updatedBody = {
    ...body,
    details: body.details === null ? '' : body.details,
  };

  if (!trackRequest) {
    updatedOrg = yield call(callUpdateOrganization, orgId, updatedBody);
  } else {
    updatedOrg = yield callApi('organization/update', callUpdateOrganization, orgId, updatedBody);
  }

  yield setState(['organizations', orgId], (org) => ({
    ...org,
    ...updatedOrg,
  }));
}
