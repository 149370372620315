import { Loader, Dimmer } from 'semantic-ui-react';
import styled from 'styled-components';

type Props = {
  message?: string;
  active?: boolean;
};

const LoaderOverlay = ({
  message,
  active,
}: Props) => (active ? (
  <Container>
    <Dimmer inverted active>
      <Loader size="large">{message || ''}</Loader>
    </Dimmer>
  </Container>
) : null);

export default LoaderOverlay;

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
`;
