import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import InputField from '../../forms/components/InputField';
import { required, minLength4 } from '../../forms/helpers';
import { asForm } from '../../util';
import Toast from '../../app/components/Toast';

type Props = {
  onSubmit: (values: any) => any;
  submitErrors: any;
  initWithValues: () => any;
};

const UserForm = ({
  handleSubmit,
  submitFailed,
  onSubmit,
  submitErrors,
  initWithValues,
  submitSucceeded,
}: InjectedFormProps & Props) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <Grid>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Field disabled label="Username" id="username" name="username" component={InputField} validate={[required, minLength4]} />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Field disabled label="User email" id="email" type="email" name="email" component={InputField} validate={[required, minLength4]} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Field disabled label="User phone" type="tel" id="mobile" name="mobile" component={InputField} validate={[required, minLength4]} />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Field disabled label="User first name" id="givenName" name="givenName" component={InputField} validate={[required, minLength4]} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={8}>
          <Field disabled label="User last name" id="familyName" name="familyName" component={InputField} validate={[required, minLength4]} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Toast error={submitFailed} message={submitErrors.general || (submitSucceeded && 'Updated')} onClose={initWithValues} />

  </form>
);

export default asForm('userForm')(UserForm);
