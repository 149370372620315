import axios from 'axios';
import { adminMode, authBaseUrl } from '../../util/constants';

export default async function revokeApiKey(apiKeyId: string, orgId: string): Promise<void> {
  await axios.put(`${authBaseUrl}/revokeApiKey?organizationId=${orgId}&apiKey=${apiKeyId}`, {
    admin: !!adminMode,
  }, {
    withCredentials: true,
  });
}
