import callAuthenticate from '../api/authenticate';
import { AuthNamespaceState } from '../types';
import { callApi, setState } from '../../util';
import { setAuthStateToStorage } from '../helpers';
import { adminMode } from '../../util/constants';

export default function* authenticate(authCode: string, redirectUri?: string) {
  const userId: string = yield callApi('authenticate', callAuthenticate, authCode, redirectUri);
  setAuthStateToStorage({
    loggedIn: true,
    isAdmin: !!adminMode,
    userId,
  });
  yield setState('auth', (): AuthNamespaceState => ({
    loggedIn: true,
    isAdmin: !!adminMode,
    userId,
  }));
}
