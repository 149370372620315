import React from 'react';
import { parse } from 'query-string';
import { Loader } from 'semantic-ui-react';
import authenticate from '../sagas/authenticate';
import { runSaga } from '../../util';

class AuthCallback extends React.Component<{
  location: Location;
  history: History & {
    replace: (...args: Array<any>) => any;
  };
}, {}> {
  componentDidMount = async () => {
    const query: {
      code?: string;
    } = parse(this.props.location.search);

    try {
      if (query.code) {
        await runSaga(authenticate, query.code);
      }
    } finally {
      this.props.history.replace('/');
    }
  };

  render() {
    return <Loader active>Signing in...</Loader>;
  }
}

export default AuthCallback;
