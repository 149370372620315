/* eslint-disable import/no-cycle */
import { AuthNamespaceState } from '../types';
import { callApi, setState } from '../../util';
import { setAuthStateToStorage } from '../helpers';
import callLogout from '../api/logout';

export default function* logout(): Generator<any> {
  try {
    yield callApi('logout', callLogout);
  } catch (err) { // catch here otherwise it gets stuck and not logged out if the request fails
  }

  localStorage.removeItem('activeOrganization');
  setAuthStateToStorage({
    loggedIn: false,
    isAdmin: false,
    userId: '',
  });
  yield setState('auth', (): AuthNamespaceState => ({
    loggedIn: false,
    isAdmin: false,
    userId: null,
  }));
}
