/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { compose } from 'redux';
import { reduxForm, getFormSubmitErrors, initialize } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { StoreState } from '../state/types';

type Props = {
  fieldValues: Record<string, unknown>;
  submitErrors: Record<string, any>;
  initWithValues: () => void;
};

type Options = {
  mapStateToProps?: (state: StoreState) => {};
  [key: string]: unknown;
}; // @todo this makes the form element re-render any time a value changes. let's see if we can avoid that

export default ((formName: string, {
  mapStateToProps: customStateToProps,
  ...options
}: Options = {}) => (WrappedComponent: any) => {
  const ComposedComponent = (props: Props) => <WrappedComponent {...props} />;

  const formStateToProps = (state: StoreState) => {
    const fieldValues = get(state.form[formName], 'values') || {};
    return {
      fieldValues,
      submitErrors: getFormSubmitErrors(formName)(state),
    };
  };

  const mergeProps = (stateProps: { fieldValues: any; }, dispatchProps: { initialize: (arg0: string, arg1: any) => any; }, ownProps: any) => ({
    initWithValues: () => dispatchProps.initialize(formName, stateProps.fieldValues),
    ...stateProps,
    ...ownProps,
  });

  const finalStateToProps = (state: StoreState) => {
    let stateProps = formStateToProps(state);

    if (customStateToProps instanceof Function) {
      stateProps = Object.assign(stateProps, customStateToProps(state));
    }

    return stateProps;
  };

  return compose(connect(finalStateToProps, {
    initialize,
  }, mergeProps), reduxForm({
    form: formName,
    ...options,
  }))(ComposedComponent);
});
