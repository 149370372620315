import axios from 'axios';
import { CreditAccount } from '../../credit/types';
import { creditBaseUrl } from '../../util/constants';

export default async function fetchCreditAccount(): Promise<CreditAccount[]> {
  const fakeId = '123456789012345678901234'; // Since we admin, it does not matter
  const allCredits = `${creditBaseUrl}/GetCreditAccount?organizationId=${fakeId}&all=true`;
  const { data: creditAccounts } : { data: CreditAccount[] } = await axios.get(allCredits, {
    withCredentials: true,
  });
  return creditAccounts;
}
