import axios from 'axios';
import { authBaseUrl } from '../../util/constants';

type PaymentMethod = 'WALLET' | 'INVOICE';

export default async function updateOrganizationPayment(orgId: string, paymentMethod: PaymentMethod): Promise<Record<string, Object>> {
  const {
    data: {
      ...rest
    },
  }: {
    data: Object;
  } = await axios.put(`${authBaseUrl}/updateOrganization/?organizationId=${orgId}`, { paymentMethod }, {
    withCredentials: true,
  });
  return {
    ...rest,
  };
}
