import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon, Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import NavMenu from './NavMenu';
import MobileMenu from './MobileMenu';
import { generateLoginUrl, runSaga } from '../../util';
import UserIsLoggedIn from '../../auth/wrappers/UserIsLoggedIn';
import HasOrganizationSelected from '../../auth/wrappers/HasOrganizationSelected';
import logout from '../../auth/sagas/logout';
import MainLogoPng from '../../static/images/main-logo.png';
import LoginSvg from '../../static/icons/login.svg';
import { getAuthStateFromStorage } from '../../auth/helpers';

// TODO: This is a temporary solution to get the isAdmin flag
function isAdmin() {
  const authState = getAuthStateFromStorage();
  if (authState) {
    return authState.isAdmin;
  }
  return false;
}

/* type Props = {
  logout: (...args: Array<any>) => any;
}; */

type State = {
  menuOpen: boolean;
  menuHeight: number;
};

const loginUrl: string = generateLoginUrl();

const getMenuHeight = () => {
  const scrollHeight = document.body ? document.body.scrollHeight : 0;
  return Math.max(scrollHeight, window.innerHeight);
};

const LoggedInAndOrganizationSelected = (Element: React.ReactElement<React.ComponentProps<any>, any>) => HasOrganizationSelected(UserIsLoggedIn(Element));

class Navbar extends React.Component<{}, State> {
  state = {
    menuOpen: false,
    menuHeight: getMenuHeight(),
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
      menuHeight: getMenuHeight(),
    }));
  };

  _logout = () => runSaga(logout);

  updateWindowDimensions() {
    this.setState({
      menuHeight: getMenuHeight(),
    });
  }

  render() {
    const {
      menuOpen,
      menuHeight,
    } = this.state;
    const menuItems = [
      LoggedInAndOrganizationSelected(<NavLink key={0} to="/organizations">Organizations</NavLink>),
      LoggedInAndOrganizationSelected(<NavLink key={1} to="/organization">Organization Profile</NavLink>),
      LoggedInAndOrganizationSelected(<NavLink key={4} to="/payment-info">Payment</NavLink>),
      LoggedInAndOrganizationSelected(<NavLink key={4} to="/orders">Orders</NavLink>),
      LoggedInAndOrganizationSelected(<NavLink key={3} to="/user">User Profile</NavLink>),
      LoggedInAndOrganizationSelected(<NavLink key={4} to="/info">Information</NavLink>),
    ];
    const logInOrLogOut = UserIsLoggedIn(
      <>
        <LoginIcon alt="" src={LoginSvg} />
        <a onClick={this._logout}>Log out</a>
      </>,
      <>
        <LoginIcon alt="" src={LoginSvg} />
        <a href={loginUrl}>Login</a>
      </>,
    );

    return (
      <div>
        <MobileMenu menuOpen={menuOpen} menuHeight={menuHeight}>
          {[...menuItems, logInOrLogOut]}
        </MobileMenu>
        <HeaderWrap isAdmin={isAdmin()}>
          <Grid stretched>
            <Grid.Row>
              <Grid.Column mobile={12} tablet={12} computer={5}>
                <LogoWrapper>
                  <a href="/">
                    <Logo
                      alt="HSL OpenMaaS Developer Portal"
                      src={MainLogoPng}
                    />
                  </a>
                  <HslH1Navbar>
                    HSL OpenMaaS
                    <br />
                    Developer Portal
                  </HslH1Navbar>
                </LogoWrapper>
              </Grid.Column>
              <Grid.Column computer={9} only="computer">
                <NavMenu>
                  {menuItems}
                </NavMenu>
              </Grid.Column>
              <Grid.Column computer={2} only="computer">
                <NavMenu>
                  {[logInOrLogOut]}
                </NavMenu>
              </Grid.Column>
              <Grid.Column mobile={4} tablet={4} only="mobile tablet">
                <IconWrap>
                  <StyledIcon onClick={this.toggleMenu} size="big" name="bars" />
                </IconWrap>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </HeaderWrap>
      </div>
    );
  }
}

export default Navbar;

interface HeaderWrapProps {
  isAdmin?: boolean;
}

const HeaderWrap = styled.header<HeaderWrapProps>`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  height: 140px;
  position: relative;
  overflow: hidden;
  z-index: 10 !important;
  background-color: ${(props) => {
    const bgColor = props.isAdmin ? 'var(--black)' : 'var(--hsl-blue)';
    return bgColor;
  }};
  margin-bottom: 50px;
  padding: 0 10px;
  @media (min-width: 768px) {
    padding: 0 20px;
  }
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledIcon = styled(Icon)`
  cursor: pointer;
  color: var(--white);
  @media (min-width: 992px) {
    display: none !important;
  }
`;

const HslH1Navbar = styled.div`
  margin: 2px 0 0 15px !important;
  display: inline-block !important;
  color: var(--white) !important;
  font-family: var(--font-header-light) !important;
  font-size: 14px !important;
  line-height: 16px !important;
  font-weight: normal !important;
  font-style: normal !important;
  font-stretch: normal !important;
  @media (min-width: 768px) {
    font-size: 18px !important;
    line-height: 20px !important;
  }
  @media (min-width: 992px) {
    font-size: 18px !important;
    line-height: 20px !important;
  }
  @media (min-width: 1200px) {
    margin: 3px 0 0 30px !important;
  }
  @media (min-width: 1500px) {
    font-size: 28px !important;
    line-height: 30px !important;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Logo = styled.img`
  max-width: 90px !important;
  flex-grow: unset !important;
  @media (min-width: 768px) {
    max-width: 110px !important;
  }
  @media (min-width: 992px) {
    max-width: 110px !important;
  }
  @media (min-width: 1200px) {
    max-width: 160px !important;
  }
`;

const LoginIcon = styled.img`
  margin-right: 10px;
`;
