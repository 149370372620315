const emailFilter = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
export const minLength = (min: number) => (value: string) => (value && value.length < min ? `Value must be at least ${min} characters.` : undefined);
export const maxLength = (max: number) => (value: string) => (value && value.length > max ? `Value cannot be more than ${max} characters.` : undefined);
// @ts-ignore
export const shouldMatch = (twinFieldId: string, twinFieldLabel?: string) => (value?: string, allValues: Record<string, string>) => {
  if (value !== allValues[twinFieldId]) {
    return `Should match ${twinFieldLabel || twinFieldId}.`;
  }
  return undefined;
};
export const minLength1 = minLength(1);
export const minLength2 = minLength(2);
export const minLength4 = minLength(4);
export const maxLength15 = maxLength(15);
export const required = (value: string | number | boolean) => (value ? undefined : 'Required field');
export const validateEmail = (value: string) => (value && !emailFilter.test(value) ? 'Invalid email address' : undefined);
