import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { selectOrganization, selectActiveOrgId } from '../selectors';
import type { Organization } from '../types';
import InviteUsersModal from './InviteUsersModal';
import ConfirmRevokeInvitationModal from './ConfirmRevokeInvitationModal';
import { selectUserId } from '../../auth/selectors';
import type { StoreState } from '../../state/types';
import CloseSvg from '../../static/icons/close.svg';

type Props = {
  organization?: Organization | null;
};
type State = {
  emailToUninvite: string | null | undefined;
};

const getDisplayExpirationText = (validTo: Date): string => {
  const fromDate = new Date();
  const toDate = new Date(validTo);
  const diff = toDate.getTime() - fromDate.getTime();
  const daysDiff = diff / (1000 * 60 * 60 * 24);
  const daysLeft = Math.trunc(daysDiff);

  if (daysDiff <= 0) return 'Expired';
  if (daysLeft === 1) return 'Expires in 1 day';
  return `Expires in ${daysLeft} days`;
};

class UserList extends Component<Props, State> {
  state = {
    emailToUninvite: undefined,
  };

  setEmailToUninvite = (emailToUninvite: string) => () => this.setState({
    emailToUninvite,
  });

  unsetEmailToUninvite = () => this.setState({
    emailToUninvite: undefined,
  });

  render() {
    const invites = this.props.organization && this.props.organization.pendingInvites;
    return (
      <Grid container centered>
        <InvitesTitleRow>
          <Grid.Column verticalAlign="middle" textAlign="left" mobile={16} tablet={16} computer={16}>
            <h3>Pending invitations</h3>
          </Grid.Column>
        </InvitesTitleRow>
        {invites?.length === 0 && <InviteRow><InviteRowNameCol><p>No invitations</p></InviteRowNameCol></InviteRow>}
        {invites && invites.map((invite, i) => (
          <InviteRow key={i}>
            <InviteRowNameCol mobile={16} tablet={16} computer={8}>
              <p>{invite.email}</p>
            </InviteRowNameCol>
            <UserRowEmailCol mobile={16} tablet={16} computer={7}>
              <p>{getDisplayExpirationText(invite.validTo)}</p>
            </UserRowEmailCol>
            <UserRowEmailCol mobile={16} tablet={16} computer={1}>
              {invite.email && <TriggerLink alt="icon" src={CloseSvg} onClick={this.setEmailToUninvite(invite.email)} />}
            </UserRowEmailCol>
          </InviteRow>
        ))}
        <Grid.Column verticalAlign="middle" textAlign="right" mobile={16} tablet={16} computer={16}>
          { /* @ts-ignore */}
          <InviteUsersModal />
        </Grid.Column>

        { /* @ts-ignore */}
        <ConfirmRevokeInvitationModal handleClose={this.unsetEmailToUninvite} email={this.state.emailToUninvite} />
      </Grid>
    );
  }
}

export default compose(connect((state: StoreState): any => {
  const ownId = selectUserId(state);
  return {
    organization: selectOrganization(state, selectActiveOrgId() || ''),
    ownId,
  };
}))(UserList);

const InviteRow = styled(Grid.Row)`
  border-top: 1px solid #dadada;
`;

const InviteRowNameCol = styled(Grid.Column)`
  text-align: start;
`;

const UserRowEmailCol = styled(Grid.Column)`
  text-align: end !important;
`;

const InvitesTitleRow = styled(Grid.Row)`
  margin-bottom: 0px;
  margin-top: 20px;
`;

const TriggerLink = styled.img`
  cursor: pointer;
`;
