import React, { Component } from 'react';
import type { InjectedFormProps } from 'redux-form';
import { FieldArray, SubmissionError } from 'redux-form';
import { Modal } from 'semantic-ui-react';
import { AxiosError } from 'axios';
import styled from 'styled-components';
import Toast from '../../app/components/Toast';
import { asForm, runSaga } from '../../util';
import HslButton from '../../forms/components/HslButton';
import EmailFieldArray from '../../credit/components/EmailFieldArray';
import callInviteUsers from '../sagas/inviteUsers';
import { isFetching } from '../../requests/selectors';

type Props = {
  submitErrors: any;
  initWithValues: () => any;
};

class InviteUsersModal extends Component<InjectedFormProps & Props, {
  modalOpen: boolean;
}> {
  state = {
    modalOpen: false,
  };

  initializeAsEmpty = () => this.props.initialize({
    invitees: [''],
  });

  handleInvite = async ({
    invitees,
  }: {
    invitees: string[];
  }) => {
    try {
      await runSaga(callInviteUsers, invitees);
      this.handleClose();
      this.initializeAsEmpty();
    } catch (e) {
      const axiosError = e as AxiosError;
      if (axiosError.response && axiosError.response.status === 409 && axiosError.response.data) {
        throw new SubmissionError({
          general: axiosError.response.data,
        });
      } else {
        throw new SubmissionError({
          general: 'Failed inviting users.',
        });
      }
    }
  };

  handleOpen = () => this.setState({
    modalOpen: true,
  });

  handleClose = () => this.setState({
    modalOpen: false,
  });

  render() {
    const {
      invalid,
      submitting,
      submitErrors,
      initWithValues,
      submitFailed,
      submitSucceeded,
      handleSubmit,
    } = this.props;

    return (
      <div>
        <TriggerLink inverse type="button" onClick={this.handleOpen}>
          Invite users
        </TriggerLink>
        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic size="small">

          { /* @ts-ignore */}
          <StyledForm onSubmit={handleSubmit(this.handleInvite)}>

            <Modal.Content>
              <h2>Invite users</h2>

              { /* @ts-ignore */}
              <FieldArray name="invitees" component={EmailFieldArray} />

            </Modal.Content>
            <hr />

            <ActionsContainer>

              <HslButton
                inverse
                onClick={this.handleClose}
                disabled={submitting}
                type="button"
              >
                Cancel
              </HslButton>

              <HslButton loading={submitting} disabled={submitting || invalid} type="submit">
                Send
              </HslButton>

            </ActionsContainer>
          </StyledForm>

        </Modal>
        <Toast
          error={submitFailed}
          message={submitErrors.general || (submitSucceeded && 'Invite emails sent successfully!')}
          onClose={initWithValues}
        />

      </div>
    );
  }
}

export default asForm('userInviteForm', {
  mapStateToProps: (state) => ({
    submitting: isFetching(state, 'user/inviteusers'),
    initialValues: {
      invitees: [''],
    },
  }),
})(InviteUsersModal);

const StyledForm = styled.form`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;import runSaga from './../../util/runSaga';

  padding: 50px;
  max-width: 550px;
  font-size: 13pt;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ActionsContainer = styled(Modal.Actions)`
  padding-top: 30px;
  text-align: center;
`;

const TriggerLink = styled(HslButton)`
  cursor: pointer;
`;
