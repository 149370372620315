import React from 'react';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

type ComponentOrElement = React.ReactNode;

const SelectorWrapper = (authenticatedSelector: (...args: Array<any>) => any, Success: ComponentOrElement, Failure?: ComponentOrElement) => {
  const FailureComponent = React.isValidElement(Failure) ? () => Failure : Failure;
  const SuccessComponent = React.isValidElement(Success) ? () => Success : Success;

  if (Failure) {
    const wrapperParams = {
      authenticatedSelector,
      wrapperDisplayName: 'SelectorWrapper',
      FailureComponent,
    };

    // @ts-ignore
    return connectedAuthWrapper(wrapperParams)(SuccessComponent);
  }

  const wrapperParams = {
    authenticatedSelector,
    wrapperDisplayName: 'SelectorWrapper',
  };

  // @ts-ignore
  return connectedAuthWrapper(wrapperParams)(SuccessComponent);
};

export default SelectorWrapper;
