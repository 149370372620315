import { Link } from 'react-router-dom';
import { Grid, List } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import Navbar from '../../app/components/Navbar';
import {
  portalPrivacyPolicyFI,
  portalPrivacyPolicyEN,
  authorisationsUiIntsructionsEN,
  pricesUrl,
  autoRenewingFI,
  autoRenewingEN,
  cancellationAndRefundEN,
  checklistFI,
  checklistEN,
  cancellingTermsFI,
  testUsersEN,
  tarmo,
} from '../constants';
import Footer from '../../app/components/Footer';
import { selectLoggedIn } from '../../auth/selectors';
import type { StoreState } from '../../state/types';

const InfoPage = () => {
  const hasPermission = connectedAuthWrapper({
    authenticatedSelector: (state: StoreState) => !!selectLoggedIn(state),
    wrapperDisplayName: 'UserHasAccessToTestUsers',
  });
  const ShowTestUsers = hasPermission(() => (
    <List.Item>
      <InfoLinkA href={testUsersEN} rel="noopener noreferrer" target="_blank">Test users for Sandbox environment (PDF)</InfoLinkA>
    </List.Item>
  ));
  return (
    <Container>
      <Helmet>
        <title>HSL - OpenMaaS Documentation</title>
        <meta name="description" content="HSL OpenMaaS ticket prices, privacy statement and service documentation." />
      </Helmet>

      <Navbar />
      <main>
        <ListContainerGrid container>
          <div>
            <List key={0} divided verticalAlign="middle" size="big" relaxed>
              <List.Item>
                <h2>Suomeksi</h2>
              </List.Item>
              <List.Item>
                <InfoLinkA href={portalPrivacyPolicyFI} rel="noopener noreferrer" target="_blank">HSL OpenMaaS -tietosuojaseloste (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={checklistFI} rel="noopener noreferrer" target="_blank">Muistilista rajapintasopimusta tekeville (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={cancellingTermsFI} rel="noopener noreferrer" target="_blank">Lippujen peruutusehdot (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={autoRenewingFI} rel="noopener noreferrer" target="_blank">Jatkuva säästötilaus (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={tarmo} rel="noopener noreferrer" target="_blank">Tariffi- ja myyntiohje (PDF)</InfoLinkA>
              </List.Item>
            </List>
            <List key={1} divided verticalAlign="middle" size="big" relaxed>
              <List.Item>
                <h2>In English</h2>
              </List.Item>
              <List.Item>
                <InfoLinkA href={portalPrivacyPolicyEN} rel="noopener noreferrer" target="_blank">HSL OpenMaaS privacy statement (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={authorisationsUiIntsructionsEN} rel="noopener noreferrer" target="_blank">HSL Authorisations UI instructions (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={checklistEN} rel="noopener noreferrer" target="_blank">Checklist for new OpenMaaS API users (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={autoRenewingEN} rel="noopener noreferrer" target="_blank">Auto-renewing saver subscription (PDF)</InfoLinkA>
              </List.Item>
              <List.Item>
                <InfoLinkA href={cancellationAndRefundEN} rel="noopener noreferrer" target="_blank">Ticket change, cancellation and refund terms (PDF)</InfoLinkA>
              </List.Item>
              { /* @ts-ignore */}
              <ShowTestUsers />
            </List>
            <List divided size="big" relaxed>
              <List.Item>
                <h2>Public API documentation</h2>
              </List.Item>
              <List.Item>
                <ApiDocCell>
                  <InfoLink to="/ticket-api-doc">Ticket API v4.1</InfoLink>
                  <InfoLinkA href="/ticketApiDoc.json" download="hsl_openmaas_ticket_api_doc_v4.json" rel="noopener noreferrer" target="_blank">Download</InfoLinkA>
                </ApiDocCell>
              </List.Item>
              <List.Item>
                <ApiDocCell>
                  <InfoLink to="/credit-api-doc">Credit API v1</InfoLink>
                  <InfoLinkA href="/creditApiDoc.json" download="hsl_openmaas_credit_api_doc_v1.json" rel="noopener noreferrer" target="_blank">Download</InfoLinkA>
                </ApiDocCell>
              </List.Item>
              <List.Item>
                <ApiDocCell>
                  <InfoLink to="/user-api-doc">User API v1</InfoLink>
                  <InfoLinkA href="/userApiDoc.json" download="hsl_openmaas_user_api_doc_v1.json" rel="noopener noreferrer" target="_blank">Download</InfoLinkA>
                </ApiDocCell>
              </List.Item>
              <List.Item>
                <ApiDocCell>
                  <InfoLink to="/supersaver-api-doc">Supersaver API v1</InfoLink>
                  <InfoLinkA href="/supersaverApiDoc.json" download="hsl_openmaas_supersaver_api_doc_v1.json" rel="noopener noreferrer" target="_blank">Download</InfoLinkA>
                </ApiDocCell>
              </List.Item>
            </List>
            <List key={0} divided verticalAlign="middle" size="big" relaxed>
              <List.Item>
                <h2>Pricing</h2>
              </List.Item>
              <List.Item>
                <InfoLinkA href={pricesUrl} rel="noopener noreferrer" target="_blank">HSL OpenMaaS Pricelist (JSON)</InfoLinkA>
              </List.Item>
            </List>
          </div>
        </ListContainerGrid>
      </main>
      <Footer />
    </Container>
  );
};

export default InfoPage;

const ApiDocCell = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .ui.list {
    margin-top: 0;
  }
`;

const ListContainerGrid = styled(Grid)`
  min-height: 60vh;
`;

const InfoLink = styled(Link)`
  color: var(--hsl-blue);
  text-decoration: underline;
  &:hover {
    color: #333;
    text-decoration: underline;
  }
`;

const InfoLinkA = styled.a`
  color: var(--hsl-blue);
  text-decoration: underline;
  &:hover {
    color: #333;
    text-decoration: underline;
  }
`;
