import { connect } from 'react-redux';
import styled from 'styled-components';
import { StoreState } from '../../state/types';
import type { CreditAccount } from '../types';
import { selectActiveOrgId } from '../../organization/selectors';
import { selectCreditAccount } from '../selectors';
import SendMoneySvg from '../../static/icons/send-money.svg';

const IncrementCreditBox = ({
  creditAccount,
}: {
  creditAccount: CreditAccount | null | undefined;
}) => (
  <KpiContainer>
    <h2>Top up your prepaid account</h2>
    <KpiContentDivider>
      <BellIcon src={SendMoneySvg} alt="notification" />
      <KpiContent>
        <p>
          <strong>IBAN</strong>
          {' '}
          FI78 1660 3001 1598 32
        </p>
        <p>
          <strong>BIC</strong>
          {' '}
          NDEAFIHH
        </p>
        <p>
          <strong>Reference number</strong>
          {` ${String(creditAccount?.referenceCode)}`}
        </p>

      </KpiContent>
    </KpiContentDivider>
  </KpiContainer>
);

export default connect((state: StoreState) => ({
  creditAccount: selectCreditAccount(state, selectActiveOrgId()),
}))(IncrementCreditBox);

const KpiContainer = styled.div`
  height: 100%;
  text-align: initial;
  background: rgb(245, 245, 245);
  padding: 20px 30px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
`;

const KpiContentDivider = styled.div`
  display: flex;
`;

const KpiContent = styled.div`
  padding-top: 10px;
  padding-left: 25px;

  & > p {
    & > strong {

      font-family: Lato, sans-serif;
    }

    margin-bottom: 5px;
  }
`;

const BellIcon = styled.img`
  align-self: baseline;
  width: 40px;
  margin-top: 10px;
`;
