import { filter } from 'lodash';
import { CreditAccount } from '../credit/types';
import { Selector } from '../state/types';
import type { Organization, ApiKey } from './types';

export const selectOrganization: Selector<Organization | null | undefined, string | null> = (state, id) => {
  if (!id) return undefined;
  return state.organizations[id];
};

export const selectSandboxApiKey: Selector<string | null | undefined, string> = (state, id) => {
  const organization = selectOrganization(state, id) || {};
  // @ts-ignore
  return organization.sandboxApiKey && organization.sandboxApiKey.apiKey;
};

export const selectActiveOrgId = (): string => localStorage.getItem('activeOrganization') || '';

// @ts-ignore
export const selectOrganizations: Selector<Array<Organization> | []> = (state) => Object.values(state.organizations);

// @ts-ignore
export const selectCreditAccounts: Selector<Array<CreditAccount> | []> = (state) => Object.values(state.creditAccounts);

// @ts-ignore
export const selectApiKeys: Selector<Array<ApiKey> | [], string | null | undefined> = (state, organizationId) => filter(state.apiKeys, {
  organizationId,
});

export const selectApiKey: Selector<ApiKey | null | undefined, string> = (state, id) => state.apiKeys[id];
