import omit from 'lodash/omit';
import { initialState } from '../state/initialState';
import type {
  RequestAction, ErrorRequestAction, RequestNameSpaceState, AsyncErrorState,
} from './types';

export const requestReducer = (state: RequestNameSpaceState = initialState.requests, action: RequestAction) => {
  switch (action.type) {
    case 'REQUEST/FAILURE':
    case 'REQUEST/FAILURE::SILENT':
    case 'REQUEST/SUCCESS':
      return omit(state, action.payload.omitKey || []);

    case 'REQUEST/START':
      return {
        ...state,
        [action.payload.key]: {
          requestKey: action.payload.requestKey,
        },
      };

    default:
      return state;
  }
};
export const asyncErrorReducer = (state: AsyncErrorState = initialState.asyncError, action: ErrorRequestAction) => {
  switch (action.type) {
    case 'REQUEST/FAILURE': {
      const { error } = action.payload;

      if (!error.response) {
        return {
          statusCode: 400,
          message: 'Network error',
        };
      }

      const {
        response,
      } = error;
      return {
        statusCode: response.status,
        message: response.data.message || response.statusText,
      };
    }
    case 'ERROR/CLEAR':
      return initialState.asyncError;

    default:
      return state;
  }
};
