import createSagaMiddleware from 'redux-saga';
import {
  combineReducers,
  applyMiddleware,
  createStore,
  // compose,
} from 'redux';
import { reducer as formReducer } from 'redux-form';
import { asyncErrorReducer, requestReducer } from '../requests/reducers';
import { initialState } from './initialState';
import type { StoreState, Action } from './types';

/**
 * Reducers
 *
 */

const mainReducer: (...args: Array<any>) => any = combineReducers({
  transactions: (state = initialState.transactions) => state,
  auth: (state = initialState.auth) => state,
  organizations: (state = initialState.organizations) => state,
  creditAccounts: (state = initialState.creditAccounts) => state,
  apiKeys: (state = initialState.apiKeys) => state,
  cards: (state = initialState.cards) => state,
  form: formReducer,
  requests: requestReducer,
  asyncError: asyncErrorReducer,
  user: (state = initialState.user) => state,
});

const rootReducer = (state: StoreState = initialState, action: Action<any>) => {
  switch (action.type) {
    case 'GENERIC/SET_STATE':
      return action.payload;

    case 'LOGOUT':
      return initialState;

    default:
      return mainReducer(state, action);
  }
};

/**
 * Store
 *
 */
export const sagaMiddleware = createSagaMiddleware();
// @ts-ignore
export const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
