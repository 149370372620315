import { call } from 'redux-saga/effects';
import type { CreditAccount } from '../types';
import { setState, callApi } from '../../util';
import callFetchCreditAccount from '../api/fetchCreditAccount';
import { selectActiveOrgId } from '../../organization/selectors';

export default function* fetchCreditAccount() {
  // @ts-ignore
  const organizationId = yield call(selectActiveOrgId);

  if (!organizationId) {
    return;
  }

  const creditAccount: CreditAccount | null | undefined = yield callApi('creditAccount/get', callFetchCreditAccount, organizationId);

  if (!creditAccount) {
    return;
  }

  yield setState(['creditAccounts', organizationId], (staleAccount: CreditAccount | null | undefined) => ({
    ...staleAccount,
    ...creditAccount,
    credit: creditAccount.credit / 100,
    alertThreshold: creditAccount.alertThreshold / 100,
  }));
}
