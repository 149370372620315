import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { StoreState } from '../../state/types';
import { selectLoggedIn } from '../../auth/selectors';

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/',
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: false,
  authenticatedSelector: (state: StoreState) => !!selectLoggedIn(state),
});

export default userIsAuthenticated;
