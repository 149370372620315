import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import { selectUser } from '../selectors';
import UserForm from './UserForm';
import { runSaga } from '../../util';
import fetchUser from '../sagas/fetchUser';
import type { User } from '../types';
import { isFetching } from '../../requests/selectors';
import Navbar from '../../app/components/Navbar';
import { openIdConnectUrl } from '../../util/constants';
import LoaderOverlay from '../../app/components/LoaderOverlay';
import type { StoreState } from '../../state/types';

type Props = {
  user: User | null | undefined;
  isFetching: boolean;
};

class UserPage extends Component<Props, {}> {
  componentDidMount() {
    if (!this.props.user) {
      runSaga(fetchUser);
    }
  }

  submitEdit = (e: React.SyntheticEvent) => {
    e.preventDefault();
  };

  render() {
    const uname = this.props.user && (this.props.user.username || '');
    return (
      <div style={{
        width: '100%',
      }}
      >
        <LoaderOverlay active={this.props.isFetching} />
        <Navbar />
        <main>
          <Grid container centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <h1>User Information</h1>
                { /* @ts-ignore */}
                <UserForm enableReinitialize onSubmit={this.submitEdit} initialValues={this.props.user} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <a className="hslLink" rel="noopener noreferrer" target="_blank" href={`${openIdConnectUrl}/changePassword?ns=hsl-maas&username=${uname}`}>Change Password</a>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </main>
      </div>
    );
  }
}

export default compose(connect((state: StoreState): any => ({
  user: selectUser(state),
  isFetching: isFetching(state, 'user/get'),
})))(UserPage);
