import axios from 'axios';
import type { ApiKeysNameSpaceState, OrganizationsNameSpaceState, OrganizationsResponse } from '../types';
import { authBaseUrl } from '../../util/constants';

export default async function fetchOrganizations(id?: string): Promise<any> {
  const URL = id ? `${authBaseUrl}/organizations?organizationId=${id}` : `${authBaseUrl}/organizations`;
  const {
    data,
  }: {
    data: {
      organizations: OrganizationsResponse;
    };
  } = await axios.get(URL, {
    withCredentials: true,
  });
  // API keys and organizations will probably come from separate endpoints in the future
  const normalizedResponse: {
    organizations: OrganizationsNameSpaceState;
    apiKeys: ApiKeysNameSpaceState;
  } = data.organizations.reduce((aggregate, {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    organizationId: id,
    organizationName: name,
    apiKeys,
    ...rest
  }) => {
    const normalizedApiKeys: ApiKeysNameSpaceState = apiKeys.reduce((aggr, apiKeyObject) => {
      if (apiKeyObject.status === 'revoked') {
        return aggr;
      }

      return {
        ...aggr,
        [apiKeyObject.id]: {
          // @ts-ignore
          organizationId: id,
          ...apiKeyObject,
        },
      };
    }, {});
    return {
      organizations: {
        ...aggregate.organizations,
        [id]: {
          id,
          name,
          ...rest,
        },
      },
      apiKeys: {
        ...aggregate.apiKeys,
        ...normalizedApiKeys,
      },
    };
  }, {
    organizations: {},
    apiKeys: {},
  });
  return normalizedResponse;
}
