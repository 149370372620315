import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { portalPrivacyPolicyFI, portalPrivacyPolicyEN } from '../../docs/constants';

const Footer = () => (
  <FooterWrap>
    <Grid container>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={10}>
          <h4>Contact information</h4>
          <FooterBlock>
            <div>
              <p>Risto Laine</p>
              <p>Commercial</p>
              <p><a className="hslLink" href="mailto:risto.k.laine@hsl.fi" rel="noopener noreferrer">risto.k.laine@hsl.fi</a></p>
            </div>
            <div>
              <p>Katja Nyman</p>
              <p>Technical</p>
              <p><a className="hslLink" href="mailto:katja.nyman@hsl.fi" rel="noopener noreferrer">katja.nyman@hsl.fi</a></p>
            </div>
          </FooterBlock>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={6}>
          <h4>Terms of Service</h4>
          <FooterBlock>
            <div>
              <p>
                <a className="hslLink" href={portalPrivacyPolicyFI} rel="noopener noreferrer" target="_blank">HSL OpenMaaS tietosuojaseloste (PDF)</a>
              </p>
              <p>
                <a className="hslLink" href={portalPrivacyPolicyEN} rel="noopener noreferrer" target="_blank">HSL OpenMaaS privacy policy (PDF)</a>
              </p>
            </div>
          </FooterBlock>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </FooterWrap>
);

export default Footer;

const FooterWrap = styled.footer`
  background: var(--hsl-footer-background);
  margin-top: 30px;
  padding: 40px 0 10px;

  & p,
  & h4 {
    font-family: var(--font-header-light);
    color: var(--black);
  }

  & p {
    font-size: 18px !important;
    margin: 0 0 3px !important;
  }

  & h4 {
    font-size: 18px;
    margin: 0 0 30px;
  }
`;

const FooterBlock = styled.div`
  & div {
    width: 100%;
    margin: 0 0 30px;
  }
  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;
