import axios from 'axios';
import { creditBaseUrl } from '../../util/constants';

export default async function adminUpdateCreditAccount(organizationId: string, updatedFields: any): Promise<any> {
  const {
    data,
  } = await axios.put(`${creditBaseUrl}/ModifyCreditAccountAdmin`, {
    organizationId,
    ...updatedFields,
  }, {
    withCredentials: true,
  });
  return data;
}
