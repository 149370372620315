import { Route } from 'react-router-dom';
import userIsAuthenticated from '../routes/guards/UserIsAuthenticated';
import OrganizationPage from './components/OrganizationPage';
import OrganizationSignup from './components/OrganizationSignup';
import Organizations from './components/Organizations';
import OrganizationsAdmin from './components/OrganizationsAdmin';
import AcceptInvitePage from './components/AcceptInvitePage';
import { adminMode } from '../util/constants';

// @ts-ignore
const Orgs = adminMode ? userIsAuthenticated(OrganizationsAdmin) : userIsAuthenticated(Organizations);
// @ts-ignore
const Org = userIsAuthenticated(OrganizationPage);
// @ts-ignore
const CreateOrg = userIsAuthenticated(OrganizationSignup);

export default [
  <Route key="organizations" path="/organizations" exact component={Orgs} />,
  // @ts-ignore
  <Route key="invitation" path="/invitation" exact component={AcceptInvitePage} />,
  <Route key="organization" path="/organization" component={Org} />,
  <Route key="create-organization" path="/create-organization" component={CreateOrg} />,
];
