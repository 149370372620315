import { Component } from 'react';
import { Container, Grid, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import styled from 'styled-components';
import { parse, stringify } from 'query-string';
import { runSaga } from '../../util';
import Navbar from '../../app/components/Navbar';
import HslButton from '../../forms/components/HslButton';
import OrderRow from './OrderRow';
import {
  selectOrderUnits,
  selectRefundedOrderUnits,
  selectTotalAmount,
  selectTotalSum,
  selectTotalSumVat,
  selectDisplayNames,
} from '../selectors';
import type { DisplayNames, OrderUnit } from '../types';
import fetchOrders from '../sagas/fetchOrders';
import fetchExcel from '../sagas/fetchExcel';
import DatePickerModal from './DatePickerModal';
import { isFetching } from '../../requests/selectors';
import type { StoreState } from '../../state/types';

type Props = {
  orderUnits: OrderUnit[];
  refundedOrderUnits: OrderUnit[];
  totalSum: number;
  totalSumVat: number;
  isFetching: boolean;
  isFetchingExcel: boolean;
  totalAmount: number;
  displayNames: DisplayNames;
  location: {
    search?: string;
  };
  history: {
    push: (url: string) => void;
    replace: (url: string) => void;
  };
};

class OrdersPage extends Component<Props> {
  twelveAM = new Date();

  now = new Date();

  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  constructor(props: Props) {
    super(props);
    // local time 12AM
    this.twelveAM.setHours(0, 0, 0, 0);
  }

  componentDidMount() {
    this.addDefaultQParams(this.props.location.search);
    this.fetchData(this.props.location.search, fetchOrders);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.location.search !== this.props.location.search) {
      this.addDefaultQParams(nextProps.location.search);
      this.fetchData(nextProps.location.search, fetchOrders);
    }
  }

  fetchData = (qs: string | null | undefined, saga: any): Promise<any> | null | undefined => {
    const filters: {
      startDate?: string;
      endDate?: string;
    } = parse(qs || '');

    if (filters.startDate && filters.endDate) {
      return runSaga(saga, filters);
    }
    return undefined;
  };

  addDefaultQParams = (qs = '') => {
    const {
      startDate,
      endDate,
    }: {
      startDate?: string;
      endDate?: string;
    } = parse(qs || '');

    if (!startDate || !endDate) {
      this.props.history.replace(`/orders?${stringify({
        startDate: format(this.yesterday, 'yyyy-MM-dd'),
        endDate: format(this.yesterday, 'yyyy-MM-dd'),
      })}`);
    }
  };

  applyRange = (dateRange: {
    startDate: string;
    endDate: string;
  }) => {
    const formattedDateRange = {
      startDate: format(new Date(dateRange.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(dateRange.endDate), 'yyyy-MM-dd'),
    };
    this.props.history.push(`/orders?${stringify(formattedDateRange)}`);
  };

  getPrintableDates = (includeYear?: boolean) => {
    // @ts-ignore
    const {
      endDate,
      startDate,
    }: {
      startDate: string;
      endDate: string;
    } = parse(this.props.location.search || '');
    const startDateOrTwelveAm = startDate ? new Date(startDate) : this.twelveAM;
    const endDateOrNow = endDate ? new Date(endDate) : this.now;
    return {
      startDate,
      endDate,
      printableStartDate: format(startDateOrTwelveAm, includeYear ? 'dd.MM.yy' : 'dd.MM'),
      printableEndDate: format(endDateOrNow, includeYear ? 'dd.MM.yy' : 'dd.MM'),
    };
  };

  fetchAndSaveExcel = async () => {
    const blob = await this.fetchData(this.props.location.search, fetchExcel);
    // const blob = await fetchExcelApi('2021-09-30T21:00:00.000Z', '2021-10-31T21:59:59.999Z', '5f6467a9b2b23920b08d8dc0');
    const newBlob = new Blob([blob]);

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(newBlob);
      return;
    }

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const objectURL = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    const {
      printableEndDate,
      printableStartDate,
    } = this.getPrintableDates(true);
    link.href = objectURL;
    link.download = `${printableStartDate}-${printableEndDate}_HSLOpenMaaS_sales.xlsx`;
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(objectURL);
    }, 100);
  };

  render() {
    const {
      orderUnits,
      refundedOrderUnits,
      totalSum,
      totalSumVat,
      totalAmount,
      displayNames,
    } = this.props;
    const {
      printableEndDate,
      printableStartDate,
      endDate,
      startDate,
    } = this.getPrintableDates(true);

    const ordersHeader = orderUnits.length ? 'Orders' : '';
    const refundsHeader = refundedOrderUnits.length ? 'Refunds' : '';

    return (
      <BaseContainer>
        <Navbar />
        <Container>
          <TotalsGrid>
            <Grid.Row>
              <DatePickerColumn mobile={16} tablet={16} computer={4}>
                <DatePickerModal
                  applyRange={this.applyRange}
                  resetRange={this.addDefaultQParams}
                  initialFrom={startDate}
                  triggerContent={`${printableStartDate} - ${printableEndDate}`}
                  initialTo={endDate}
                />
                {this.props.isFetching && <Loader inline active />}
              </DatePickerColumn>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                <h3>Total amount:</h3>
                <p>
                  {totalAmount}
                </p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={4}>
                <h3>Total sum:</h3>
                <p>
                  {totalSumVat.toFixed(2)}
                  {' '}
                  €
                </p>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={2}>
                <h3>Total VAT:</h3>
                <p>
                  {(totalSumVat - totalSum).toFixed(2)}
                  {' '}
                  €
                </p>
              </Grid.Column>
              <ButtonColumn mobile={16} tablet={16} computer={2}>
                <HslButton disabled={this.props.isFetchingExcel} onClick={this.fetchAndSaveExcel}>
                  {this.props.isFetchingExcel ? <Loader active inline /> : 'EXCEL'}
                </HslButton>
              </ButtonColumn>
            </Grid.Row>
          </TotalsGrid>

          <h1>{ordersHeader}</h1>

          {orderUnits.map((orderUnit, i) => <OrderRow grey={i % 2 === 0} key={i} day={orderUnit.day} orderUnit={orderUnit} isRefund={false} displayNames={displayNames} />)}

          <h1>{refundsHeader}</h1>

          {refundedOrderUnits.map((orderUnit, i) => <OrderRow grey={i % 2 === 0} key={i} day={orderUnit.day} orderUnit={orderUnit} isRefund displayNames={displayNames} />)}

          <ReceiptInfo>
            <p>
              HSL Helsinki Regional Transport Authority
              <br />
              Business ID: 2274586-3
              <br />
              VAT Number: FI22745863
              <br />
            </p>
          </ReceiptInfo>
        </Container>
      </BaseContainer>
    );
  }
}

export default connect((state: StoreState): any => ({
  isFetching: isFetching(state, 'order/many'),
  isFetchingExcel: isFetching(state, 'order/excel'),
  orderUnits: selectOrderUnits(state),
  refundedOrderUnits: selectRefundedOrderUnits(state),
  totalAmount: selectTotalAmount(state),
  totalSum: selectTotalSum(state),
  totalSumVat: selectTotalSumVat(state),
  displayNames: selectDisplayNames(state),
}))(OrdersPage);

const ReceiptInfo = styled.div`
  padding-top: 4rem;

  & > p {
    line-height: 25px;
    font-size: 16px;
    font-weight: bold;
  }
`;

/* .pdfButton,
.datePickerButton {
  width: auto;
  margin: 0 !important;
}

.pdfButton {
  width: 100%;
}
*/

const ButtonColumn = styled(Grid.Column)`
  display: flex !important;
  align-items: center;
`;

const DatePickerColumn = styled(ButtonColumn)`
  & > :global(.ui.loader) {
    margin-left: 20px;
  }
`;

const TotalsGrid = styled(Grid)`
  background-color: #e8f7ff;
`;

const BaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .listContainer {
    max-height: 60vh;
    overflow-y: scroll;
  }
  .footerWrap {
    flex: 1;
  }
`;
