import React from 'react';
import type { InjectedFormProps } from 'redux-form';
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';
import InputField from '../../forms/components/InputField';
import {
  required, minLength1, minLength2, minLength4, validateEmail,
} from '../../forms/helpers';
import Toast from '../../app/components/Toast';
import HslButton from '../../forms/components/HslButton';
import { authorisationsUiIntsructionsEN, portalPrivacyPolicyFI, portalPrivacyPolicyEN } from '../../docs/constants';
import type { SignupFormFields } from '../../forms/types';

type CustomLabelProps = {
  children: any;
};

const CustomLabel = ({
  children,
// eslint-disable-next-line jsx-a11y/label-has-associated-control
}: CustomLabelProps) => <label>{children}</label>;

type Props = {
  submitErrors: any;
  submit: (data: SignupFormFields) => Promise<void>;
  initWithValues: () => any;
};

const SignupForm = ({
  handleSubmit,
  submit,
  submitErrors,
  initWithValues,
  submitting,
  invalid,
}: InjectedFormProps<Props> & Props) => (
  <>
    { /* @ts-ignore */ }
    <form onSubmit={handleSubmit(submit)}>

      <Grid container columns={2}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field
              label={(<CustomLabel>Organization name *<sup>1</sup></CustomLabel>)}
              type="text"
              id="organizationName"
              name="organizationName"
              component={InputField}
              validate={[required, minLength2]}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field
              label={(<CustomLabel>App or Service name<sup>2</sup></CustomLabel>)}
              type="text"
              id="appName"
              name="appName"
              component={InputField}
              validate={[required, minLength1]}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field label="Organization email for HSL use *" type="email" id="orgemail" name="organizationEmail" component={InputField} validate={[required, validateEmail]} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field label="Organization phone for HSL use *" type="tel" id="orgphone" name="organizationPhone" component={InputField} validate={[required, minLength4]} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field label="Customer service email" type="email" id="customerServiceEmail" name="customerServiceEmail" component={InputField} validate={validateEmail} />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <Field label="Customer service phone" type="tel" id="customerServicePhone" name="customerServicePhone" component={InputField} validate={[minLength4]} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column computer={16}>
            <p>
              <sup>1</sup>
              {' '}
              This field is displayed in HSL Authorisations UI to your app users, so please pick a name your users will recognize.
              {' '}
              <a href={authorisationsUiIntsructionsEN} rel="noopener noreferrer" target="_blank">Read more.</a>
            </p>
            <p>
              <sup>2</sup>
              {' '}
              This field is displayed in tickets
              {' '}
              <i>"bought from service ***"</i>
              .
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h3>In English</h3>
            <a href={portalPrivacyPolicyEN} rel="noopener noreferrer" target="_blank">HSL OpenMaaS privacy policy</a>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={8}>
            <h3>Suomeksi</h3>
            <a href={portalPrivacyPolicyFI} rel="noopener noreferrer" target="_blank">HSL OpenMaaS tietosuojaseloste</a>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {
              /* <TermsList size="small"/> */
            }
            <Field className="hslCheckbox" name="termsAccepted" id="termsAccepted" type="checkbox" useLabel label="I have read the HSL OpenMaaS privacy policy *" component={InputField} validate={required} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <HslButton loading={submitting} disabled={invalid || submitting} type="submit">Create</HslButton>
          </Grid.Column>
        </Grid.Row>
      </Grid>

    </form>
    <Toast error message={submitErrors.general} onClose={initWithValues} />
  </>
);

export default SignupForm;
