import type { Selector } from '../state/types';
import type { DisplayNames, OrderUnit } from './types';

export const selectOrderUnits: Selector<OrderUnit[] | []> = (state) => Object.values(state.transactions.orders);
export const selectRefundedOrderUnits: Selector<OrderUnit[] | []> = (state) => Object.values(state.transactions.refundedOrders);

// @ts-ignore
export const selectOrderUnit: Selector<OrderUnit | null | undefined, string> = (state, day) => state.transactions.orders[day];
// @ts-ignore
export const selectRefundedOrderUnit: Selector<OrderUnit | null | undefined, string> = (state, day) => state.transactions.refundedOrders[day];

export const selectTotalSum: Selector<number> = ({
  transactions: {
    totalSum = 0,
  },
}) => totalSum;

export const selectTotalSumVat: Selector<number> = ({
  transactions: {
    totalSumVat = 0,
  },
}) => totalSumVat;

export const selectTotalAmount: Selector<number | null | undefined> = ({
  transactions: {
    totalAmount = 0,
  },
}) => totalAmount;

export const selectDisplayNames: Selector<DisplayNames> = ({
  transactions: {
    displayNames = {},
  },
}) => displayNames as DisplayNames;
