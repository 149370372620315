import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import { selectLoggedIn } from '../../auth/selectors';
import { StoreState } from '../../state/types';

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: '/organizations',
  wrapperDisplayName: 'UserIsNotAuthenticated',
  allowRedirectBack: false,
  authenticatedSelector: (state: StoreState) => !selectLoggedIn(state),
});

export default userIsNotAuthenticated;
