import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { fi } from 'date-fns/locale';
import { Grid, SemanticWIDTHS } from 'semantic-ui-react';
import styled from 'styled-components';
import { selectOrganizations, selectCreditAccounts } from '../selectors';
import { runSaga } from '../../util';
import fetchOrganizations from '../sagas/fetchOrganization';
import fetchCreditAccounts from '../sagas/fetchCreditAccounts';
import { isFetching } from '../../requests/selectors';
import Navbar from '../../app/components/Navbar';
import LoaderOverlay from '../../app/components/LoaderOverlay';
import Table from '../../app/components/Table';
import type { StoreState } from '../../state/types';
import HslButton from '../../forms/components/HslButton';
import { Filtering } from './Filtering';
import { AdminOrganization, Filters } from '../types';
import { CreditAccount } from '../../credit/types';

const getFilters = (): Filters => {
  const enabledParam = new URLSearchParams(window.location.search).get('filter');
  const selectedPaymentMethodParam = new URLSearchParams(window.location.search).get('paymentMethod');
  const productionAccessParam = new URLSearchParams(window.location.search).get('productionAccess');

  if (!enabledParam || !selectedPaymentMethodParam || !productionAccessParam) {
    return {
      enabled: false,
      selectedPaymentMethod: 'all',
      productionAccess: false,
    };
  }

  return {
    enabled: enabledParam === 'true',
    selectedPaymentMethod: selectedPaymentMethodParam as string,
    productionAccess: productionAccessParam === 'true',
  };
};

const formatDisplayAdminOrganizations = (organizations: AdminOrganization[], creditAccounts: CreditAccount[]) : AdminOrganization[] => {
  const normalized = organizations.map((organization) => {
    const creditAccount = creditAccounts.find((ca) => ca.organizationId === organization.id);
    const displayOrganization = { ...organization };
    displayOrganization.productionAccess = organization.contractApis?.includes('public') ? 'X' : '' || '';
    displayOrganization.createdAtDisplay = organization.createdAt ? format(new Date(organization.createdAt), 'Pp', { locale: fi }) : '';
    displayOrganization.updatedAtDisplay = organization.updatedAt ? format(new Date(organization.updatedAt), 'Pp', { locale: fi }) : '';
    displayOrganization.hslCustomerId = creditAccount?.hslCustomerId as string | undefined;
    displayOrganization.referenceCode = creditAccount?.referenceCode;
    return displayOrganization;
  });

  return normalized as AdminOrganization[];
};

const filterOrganizations = (filters: Filters, organizations: AdminOrganization[]): AdminOrganization[] => {
  let filtered = [...organizations];

  if (filters.selectedPaymentMethod.toLocaleLowerCase() !== 'all') {
    filtered = filtered.filter((organization) => organization.paymentMethod?.toLocaleLowerCase() === filters.selectedPaymentMethod?.toLocaleLowerCase());
  }
  if (filters.productionAccess) filtered = filtered.filter((organization) => organization.productionAccess?.toLocaleLowerCase() === 'x');

  return filtered;
};

const Organizations = () => {
  const history = useHistory();
  let organizations = useSelector(selectOrganizations) as AdminOrganization[];
  const creditAccounts = useSelector(selectCreditAccounts);
  let isLoading = useSelector((state: StoreState) => isFetching(state, 'organization/get'));
  isLoading = useSelector((state: StoreState) => isFetching(state, 'creditAccounts/get'));

  useEffect(() => {
    runSaga(fetchCreditAccounts);
  }, []);
  useEffect(() => {
    runSaga(fetchOrganizations);
  }, []);

  organizations = formatDisplayAdminOrganizations(organizations, creditAccounts);

  const filters = getFilters();
  if (filters && filters.enabled) {
    organizations = filterOrganizations(filters, organizations);
  }

  organizations = organizations.sort((org1, org2) => new Date(org2.createdAt as string).getTime() - new Date(org1.createdAt as string).getTime());

  organizations.forEach((org) => { console.log(`${org.createdAt} - ${org.createdAtDisplay}`); });

  const selectAsActive = (id: string) => {
    localStorage.setItem('activeOrganization', id);
    history.push('/organization');
  };

  const nameWidth: SemanticWIDTHS = 3;
  const idWidth: SemanticWIDTHS = 4;
  const customerNumberWidth: SemanticWIDTHS = 3;
  const referenceCodeWidth: SemanticWIDTHS = 4;
  const paymentMethodWidth: SemanticWIDTHS = 2;
  const productionAccessWidth: SemanticWIDTHS = 2;
  const createdAtWidth: SemanticWIDTHS = 2;
  const createdByWidth: SemanticWIDTHS = 2;
  const updatedAtWidth: SemanticWIDTHS = 2;
  const updatedByWidth: SemanticWIDTHS = 2;
  const columns = [
    {
      id: 'name',
      header: 'Name',
      accessor: 'name',
      width: nameWidth,
    },
    {
      id: 'id',
      header: 'Organization ID',
      accessor: 'id',
      width: idWidth,
    },
    {
      id: 'hslCustomerId',
      header: 'Customer number',
      accessor: 'hslCustomerId',
      width: customerNumberWidth,
    },
    {
      id: 'referenceCode',
      header: 'Reference code',
      accessor: 'referenceCode',
      width: referenceCodeWidth,
    },
    {
      id: 'productionAccess',
      header: 'Production access',
      accessor: 'productionAccess',
      width: productionAccessWidth,
    },
    {
      id: 'paymentMethod',
      header: 'Payment method',
      accessor: 'paymentMethod',
      width: paymentMethodWidth,
    },
    {
      id: 'createdAtDisplay',
      header: 'Created at',
      accessor: 'createdAtDisplay',
      width: createdAtWidth,
    },
    {
      id: 'createdBy',
      header: 'Created by',
      accessor: 'createdBy',
      width: createdByWidth,
    },
    {
      id: 'updatedAtDisplay',
      header: 'Updated at',
      accessor: 'updatedAtDisplay',
      width: updatedAtWidth,
    },
    {
      id: 'updatedBy',
      header: 'Updated by',
      accessor: 'updatedBy',
      width: updatedByWidth,
    },
  ];

  return (
    <>
      <Navbar />
      <LoaderOverlay active={isLoading} />
      <Main>
        <Grid>

          <Grid.Row>
            <Grid.Column width={10}>
              <h1>Organizations</h1>
            </Grid.Column>
            <Grid.Column width={6} textAlign="right">
              <HslButton inverse href="/create-organization">Create new</HslButton>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={16}>
              <Filtering filters={filters} />
              <Table
                data={organizations}
                columns={columns}
                onRowClick={selectAsActive}
              />
            </Grid.Column>
          </Grid.Row>

        </Grid>
      </Main>
    </>
  );
};

const Main = styled.main`
  padding-left: 2rem;
  padding-right: 2rem;
`;

export default Organizations;
