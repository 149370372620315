import { Route, Switch } from 'react-router-dom';
import authRoutes from '../auth/routes';
import cardRoutes from '../cards/routes';
import organizationRoutes from '../organization/routes';
import dashboardRoutes from '../dashboard/routes';
import userRoutes from '../user/routes';
import docsRoutes from '../docs/routes';
import ordersRoutes from '../orders/routes';
import paymentRoutes from '../payment/routes';
import NotFound from '../app/components/NotFound';
import LanderPage from '../lander/components/LanderPage';
import userIsNotAuthenticated from './guards/UserIsNotAuthenticated';

const Lander = userIsNotAuthenticated(LanderPage);

const routes = [
  ...paymentRoutes,
  ...authRoutes,
  ...ordersRoutes,
  ...organizationRoutes,
  ...cardRoutes,
  ...dashboardRoutes,
  ...userRoutes,
  ...docsRoutes,
  <Route key="lander" exact path="/" component={Lander} />,
  <Route key="notfound" component={NotFound} />,
];

export default <Switch>{routes}</Switch>;
