import { callApi, setState } from '../../util';
import callFetchOrders from '../api/fetchOrders';
import { OrderNameSpaceState, TransactionsResponse } from '../types';
import { selectActiveOrgId } from '../../organization/selectors';

type P = {
  startDate: string;
  endDate: string;
};

export default function* fetchOrders({
  startDate,
  endDate,
}: P) {
  const data: TransactionsResponse = yield callApi('order/many', callFetchOrders, startDate, endDate, selectActiveOrgId());
  yield setState('transactions', (): OrderNameSpaceState => data);
}
