import find from 'lodash/find';
import type { StoreState } from '../state/types';
import type { RequestNameSpaceState } from './types';

export const isFetching = (state: StoreState, requestKey?: string): boolean => {
  const requestsState: RequestNameSpaceState = state.requests;
  return requestKey ? !!find(requestsState, (request) => requestKey === request?.requestKey) : !!Object.keys(requestsState).length;
};
export const selectApiErrorMessage = (state: StoreState): void | string => {
  const { asyncError } = state;
  return asyncError.message;
};
