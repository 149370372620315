import React, { Component } from 'react';
import DayPicker, { Modifier } from 'react-day-picker';
import styled from 'styled-components';
import HslButton from '../../forms/components/HslButton';

type P = {
  initialFrom?: string;
  initialTo?: string;
  resetRange: () => void;
  applyRange: (payload: {
    startDate: string;
    endDate: string;
  }) => any;
};

type S = {
  from: Date;
  to: Date;
};

/**
 * Formats dates to be start or end of day in browser local time
 *
 * @param {*} date The datepicker returns dates in 12AM UTC.
 * @param {*} fromOrTo desired format result, from or to-date
 */
function formatDate(date: Date, fromOrTo: 'from' | 'to') {
  const mutableDate = new Date(date);

  if (fromOrTo === 'from') {
    mutableDate.setHours(0);
  } else {
    mutableDate.setHours(23);
    mutableDate.setMinutes(59);
    mutableDate.setSeconds(59);
    mutableDate.setMilliseconds(999);
  }

  return mutableDate;
}

/**
 * This component is a copy of an example from react-day-picker's docs.
 */
export default class DateRangePicker extends Component<P, S> {
  now = new Date();

  yesterday = new Date(new Date().setDate(new Date().getDate() - 1));

  state = {
    from: this.props.initialFrom ? new Date(this.props.initialFrom) : formatDate(this.yesterday, 'from'),
    to: this.props.initialTo ? new Date(this.props.initialTo) : this.yesterday,
  };

  handleFromDayClick = (day: Date) => {
    const { to } = this.state;
    this.setState({
      from: day,
      to,
    });
  };

  handleToDayClick = (day: Date) => {
    const { from } = this.state;
    this.setState({
      from,
      to: day,
    });
  };

  applyRange = () => {
    if (this.state.from && this.state.to) {
      this.props.applyRange({
        startDate: this.state.from.toISOString(),
        endDate: this.state.to.toISOString(),
      });
    }
  };

  resetRange = () => {
    this.props.resetRange();
    this.setState({
      from: this.props.initialFrom ? new Date(this.props.initialFrom) : formatDate(this.yesterday, 'from'),
      to: this.props.initialTo ? new Date(this.props.initialTo) : this.yesterday,
    });
  };

  render() {
    const { from, to } = this.state;
    const fromMonth = new Date(from.getFullYear(), from.getMonth());
    const toMonth = new Date(to.getFullYear(), to.getMonth());
    const modifiers: Modifier | Modifier[] = [
      {
        from,
        to,
      },
    ];
    return (
      <div>
        <style>{disabledStyle}</style>
        <DateRangePickerWrapper>
          <DatePickerWrapper>
            <DatePickerHeader>Start Date</DatePickerHeader>
            <DayPicker
              locale="fi"
              numberOfMonths={1}
              month={fromMonth}
              selectedDays={modifiers}
              disabledDays={{ after: this.yesterday }}
              onDayClick={this.handleFromDayClick}
            />
          </DatePickerWrapper>
          <DatePickerWrapper>
            <DatePickerHeader>End Date</DatePickerHeader>
            <DayPicker
              locale="fi"
              numberOfMonths={1}
              month={toMonth}
              selectedDays={modifiers}
              disabledDays={{ before: from, after: this.yesterday }}
              onDayClick={this.handleToDayClick}
            />
          </DatePickerWrapper>
        </DateRangePickerWrapper>

        <ButtonsContainer>
          <HslButtonCustom fluid onClick={this.applyRange}>
            OK
          </HslButtonCustom>
          <HslButtonCustom onClick={this.resetRange}>
            Reset
          </HslButtonCustom>
        </ButtonsContainer>
      </div>
    );
  }
}

const DateRangePickerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const DatePickerHeader = styled.p`
  padding-top: 10px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const HslButtonCustom = styled(HslButton)`
  flex: 1;
`;

const disabledStyle = `.DayPicker-Day--disabled {
  cursor: default;
  pointer-events: none;
}`;
