import { call } from 'redux-saga/effects';
import { sagaMiddleware } from '../state';

function* preventThrow(fn: (arg0: any, arg1?: any, arg2?: any) => any, ...args: Array<unknown>) {
  try {
    // @ts-ignore
    return yield call(fn, ...args);
  } catch (e) {
    return Promise.reject(e);
  }
}

export default function runSaga(fn: (arg0: any, arg1?: any, arg2?: any) => any, ...args: Array<unknown>): Promise<any> {
  // @ts-ignore
  return sagaMiddleware.run(preventThrow, fn, ...args).toPromise();
}
