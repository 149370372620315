import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import type { StoreState } from '../../state/types';
import HslButton from '../../forms/components/HslButton';
import InputField from '../../forms/components/InputField';
import { selectActiveOrgId } from '../selectors';
import requestApiKey from '../sagas/requestApiKey';
import { runSaga } from '../../util';
import { isFetching } from '../../requests/selectors';

type Props = {
  sandbox?: boolean;
  organizationId?: string | null;
  isRequesting?: boolean;
};

class RequestApiKeyModal extends Component<Props, {
  modalOpen: boolean;
  newApiKey?: string;
  newKeyLabel: string;
}> {
  state = {
    modalOpen: false,
    newKeyLabel: '',
    newApiKey: undefined,
  };

  handleOpen = () => this.setState({
    modalOpen: true,
  });

  handleClose = () => this.setState({
    modalOpen: false,
    newApiKey: undefined,
    newKeyLabel: '',
  });

  handleRequest = async () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const clearTextApiKey = await runSaga(requestApiKey, this.props.organizationId, this.state.newKeyLabel, this.props.sandbox);
    this.setState({
      newApiKey: clearTextApiKey,
      newKeyLabel: '',
    });
  };

  changeNewKeyLabel = (e: Record<string, any>) => {
    this.setState({
      newKeyLabel: e.target.value || '',
    });
  };

  render() {
    const {
      newApiKey,
      newKeyLabel,
    } = this.state;
    const sandboxString = this.props.sandbox ? 'Sandbox ' : '';
    return (
      <>

        <HslButton inverse={this.props.sandbox} primary wide onClick={this.handleOpen}>
          {this.props.sandbox ? 'Request Sandbox API Key' : 'Request API Key'}
        </HslButton>

        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic size="small">

          <ModalContainer>
            <Modal.Content>
              <h2>
                {newApiKey ? `Your new ${sandboxString}API Key` : `Request a new ${sandboxString}API Key`}
              </h2>
              <ModalContent>
                {newApiKey ? 'Here\'s your new API Key. Keep it secret. Keep it safe. After closing this dialog, nobody will be able to retrieve it.' : 'An API Key works like a password and grants your organisation access to the HSL Sales API.'}
                <br />
                <br />
                <Strong>
                  {newApiKey ? newKeyLabel : 'Define a human readable label for your API key.'}
                </Strong>

                {newApiKey ? <Strong>{newApiKey}</Strong> : (
                  <InputField
                    id="request-api-key-label-input"
                    type="text"
                    meta={{}}
                    placeholder="API Key Label"
                    label="Define a human readable label for your API key."
                    input={{ onChange: this.changeNewKeyLabel }}
                    // @ts-ignore
                    value={this.state.newKeyLabel}
                  />
                )}

              </ModalContent>

            </Modal.Content>
            <hr />

            <ModalActions>

              <HslButton
                inverse
                disabled={this.props.isRequesting}
                onClick={this.handleClose}
                type="button"
              >
                {newApiKey ? 'Close' : 'Cancel'}
              </HslButton>

              <HslButton
                disabled={!newKeyLabel.length || this.props.isRequesting}
                loading={this.props.isRequesting}
                onClick={this.handleRequest}
              >
                {this.props.sandbox ? 'Generate Sandbox API Key' : 'Generate API Key'}
              </HslButton>

            </ModalActions>
          </ModalContainer>
        </Modal>
      </>
    );
  }
}

export default connect((state: StoreState) => {
  const organizationId = selectActiveOrgId();
  return {
    organizationId,
    isRequesting: isFetching(state, 'apiKey/request'),
  };
})(RequestApiKeyModal);

const Strong = styled.strong`
  font-family: Lato, sans-serif;
`;

const ModalContainer = styled.div`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;
  padding: 50px;
  max-width: 550px;
  font-size: 13pt;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ModalContent = styled.div`
  padding: 30px 0 20px;
`;

const ModalActions = styled(Modal.Actions)`
  display: flex;
  padding-top: 30px;
  text-align: center;
`;
