import { callApi, setState } from '../../util';
import callRequestApiKey from '../api/requestApiKey';
import { ApiKeysNameSpaceState, ApiKey } from '../types';

export default function* requestApiKey(orgId: string, label: string, sandbox: boolean) {
  // Don't persist the cleartext API key value
  const {
    apiKey,
    ...dataToPersist
  }: ApiKey = yield callApi('apiKey/request', callRequestApiKey, orgId, label, sandbox);
  yield setState('apiKeys', (state: ApiKeysNameSpaceState) => ({
    ...state,
    [dataToPersist.id]: dataToPersist,
  }));
  return apiKey;
}
