import React from 'react';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { selectLoggedIn } from '../selectors';
import { StoreState } from '../../state/types';

type ComponentOrElement = React.Component<any> | React.ReactElement<React.ComponentProps<any>, any>;

const UserIsLoggedIn = (Success: ComponentOrElement, Failure?: ComponentOrElement) => {
  const FailureComponent = React.isValidElement(Failure) ? () => Failure : Failure;
  const SuccessComponent = React.isValidElement(Success) ? () => Success : Success;

  if (Failure) {
    const wrapperParams = {
      authenticatedSelector: (state: StoreState) => !!selectLoggedIn(state),
      wrapperDisplayName: 'UserIsLoggedIn',
      FailureComponent,
    };
    // @ts-ignore
    return connectedAuthWrapper(wrapperParams)(SuccessComponent);
  }

  const wrapperParams = {
    authenticatedSelector: (state: StoreState) => !!selectLoggedIn(state),
    wrapperDisplayName: 'UserIsLoggedIn',
  };
  // @ts-ignore
  return connectedAuthWrapper(wrapperParams)(SuccessComponent);
};

export default UserIsLoggedIn;
