import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import type { InjectedFormProps } from 'redux-form';
import styled from 'styled-components';
import Toast from '../../app/components/Toast';
import { runSaga, asForm } from '../../util';
import HslButton from '../../forms/components/HslButton';
import { isFetching } from '../../requests/selectors';
import removeUser from '../sagas/removeUser';

type Props = {
  user: {
    email: string;
    name: string;
    id: string;
  };
  handleClose: () => any;
  submitErrors: any;
};

type State = {
  modalOpen: boolean;
};

class ConfirmRemoveUserModal extends Component<InjectedFormProps & Props, State> {
  removeUser = async () => {
    await runSaga(removeUser, this.props.user);
    this.props.handleClose();
  };

  render() {
    const {
      user: {
        name,
        email,
      } = {},
      submitting,
      handleSubmit,
      submitFailed,
      submitSucceeded,
      submitErrors,
      initialize,
    } = this.props;
    return (
      <div>
        <Modal open={!!this.props.user} onClose={this.props.handleClose} basic size="small">
          <StyledForm onSubmit={handleSubmit(this.removeUser)}>

            <ContentContainer>
              <h2>Confirm user removal</h2>
              <p>
                You are about to remove
                <strong> {name}</strong>
                {' '}
                (
                {email}
                ) from the organization. Are you sure you want to proceed?
              </p>
            </ContentContainer>
            <hr />

            <ActionsContainer>

              <HslButton
                inverse
                onClick={this.props.handleClose}
                disabled={submitting}
                type="button"
              >
                Cancel
              </HslButton>

              <HslButton loading={submitting} disabled={submitting} type="submit">
                Remove User
              </HslButton>

            </ActionsContainer>

          </StyledForm>
        </Modal>
        <Toast
          error={submitFailed}
          message={submitErrors.general || (submitSucceeded && 'User successfully removed from the organization')}
          onClose={initialize}
        />
      </div>
    );
  }
}

export default asForm('uninviteForm', {
  mapStateToProps: (state) => ({
    submitting: isFetching(state, 'user/remove'),
  }),
})(ConfirmRemoveUserModal);

const StyledForm = styled.form`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;
  padding: 50px;
  max-width: 550px;
  font-size: 13pt;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ActionsContainer = styled(Modal.Actions)`
  padding-top: 30px;
  text-align: center;
`;

const ContentContainer = styled(Modal.Content)`
  padding-bottom: 25px;
  & > h2 {
    margin-bottom: 27px;
  }
`;
