import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Button } from 'semantic-ui-react';
import Navbar from '../../app/components/Navbar';
import Footer from '../../app/components/Footer';

class Dashboard extends Component<any> {
  logout = () => this.props.logout();

  render() {
    return (
      <div className="page">
        <Navbar />

        <Grid container centered columns={1}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={12} computer={8}>
              <p>Welcome to Dashboard!</p>
              <Button onClick={this.logout}>Sign out</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Footer />
      </div>
    );
  }
}

export default connect(undefined, (dispatch) => ({
  logout: () => dispatch({
    type: 'LOGOUT',
  }),
}))(Dashboard);
