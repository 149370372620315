import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

const NavMenu = ({
  children,
}: Props) => (
  <NavMenuList>
    { /* @ts-ignore */ }
    {children.map((Child, index) => <NavMenuItem key={index}><Child /></NavMenuItem>)}
  </NavMenuList>
);

export default NavMenu;

const NavMenuList = styled.ul`
  display: none;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  @media (min-width: 992px) {
    display: flex;
  }
`;

const NavMenuItem = styled.li`
  display: flex;
  align-items: center;
  margin: 15px 22px;
  padding: 0;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & a {
    cursor: pointer;
    font-family: GothamRounded-Medium;
    margin: 0;
    padding: 0 0 2px;
    color: var(--white);
    font-size: 18px;

    &:hover,
    &:focus,
    &:active {
      color: var(--white);
    }
  }

  & > a {
    border-bottom: 2px solid var(--hsl-blue);

    &:hover,
    &:focus,
    &:active,
    &.active {
      border-bottom: 2px solid var(--white);
    }
  }

  @media (max-width: 991px) {
    & > a {
      &.active {
        background-color: var(--hsl-darkblue);
      }
    }
  }

  @media (min-width: 992px) {
    margin: 15px;

    & a {
      font-size: 16px;
    }

    & img {
      width: 18px;
    }
  }

  @media (min-width: 1200px) {
    margin: 15px;

    & a {
      font-size: 16px;
    }

    & img {
      height: 32px;
      width: 28px;
    }
  }

  @media (min-width: 1700px) {
    margin: 15px 22px;

    & a {
      font-size: 18px;
    }

    & img {
      height: 32px;
      width: 28px;
    }
  }
`;
