import React, { Component } from 'react';
import styled from 'styled-components';
import type { Organization } from '../../organization/types';
import Navbar from '../../app/components/Navbar';
import type { CreditAccount } from '../../credit/types';
import CreditSection from '../../credit/components/CreditSection';

type Props = {
  isFetchingData: boolean;
  organization: Organization;
  creditAccount: CreditAccount | null | undefined;
};

class PaymentInfoPage extends Component<Props, {}> {
  render() {
    return (
      <Container>
        <Navbar />
        <CreditSection />
      </Container>
    );
  }
}

export default PaymentInfoPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
