/* eslint-disable no-mixed-operators */
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { selectOrganizations } from '../selectors';
import { runSaga } from '../../util';
import fetchOrganizations from '../sagas/fetchOrganization';
import type { Organization } from '../types';
import { isFetching } from '../../requests/selectors';
import Navbar from '../../app/components/Navbar';
import LoaderOverlay from '../../app/components/LoaderOverlay';
import InputField from '../../forms/components/InputField';
import type { StoreState } from '../../state/types';

type Props = {
  organizations: Organization[];
  history: {
    replace: (...args: Array<any>) => any;
  };
  isFetching: boolean;
};

type State = {
  organizations: Organization[];
  keywordActive: boolean;
};

class Organizations extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      organizations: [],
      keywordActive: false,
    };
  }

  componentDidMount() {
    runSaga(fetchOrganizations);
    this.setState({
      organizations: this.props.organizations,
    });
  }

  selectAsActive = (id: string) => () => {
    localStorage.setItem('activeOrganization', id);
    this.props.history.replace('/organization');
  };

  searchSpace = (event: React.ChangeEvent<HTMLInputElement>) => {
    const keyword = event.target.value;
    let resultOrganizations = this.props.organizations;
    const regexp = new RegExp(keyword, 'i');

    if (keyword.length > 0) {
      resultOrganizations = this.props.organizations.filter((organization) => {
        if (
          organization.name.match(regexp)
          || organization.customerServiceEmail
          && organization.customerServiceEmail.match(regexp)
          || organization.organizationEmail
          && organization.organizationEmail.match(regexp)
          || organization.organizationPhone
          && organization.organizationPhone.match(regexp)
          || organization.appName
          && organization.appName.match(regexp)
          || organization.customerServicePhone
          && organization.customerServicePhone.match(regexp)
        ) {
          return organization;
        }

        return null;
      });
    }

    this.setState({
      organizations: resultOrganizations,
      keywordActive: keyword.length > 0,
    });
  };

  render() {
    let { organizations } = this.props;

    // if keyword is in use. Otherwise we show all as default
    if (this.state.keywordActive === true) {
      organizations = this.state.organizations;
    }

    return (
      <>
        <Navbar />
        <LoaderOverlay active={this.props.isFetching} />
        <main>
          <Grid container columns={3} stackable>

            <TitleRow>
              <Grid.Column width={6}>
                <h1>Select Organization</h1>
                <InputField
                  id="organization-search-input"
                  input={{
                    onChange: this.searchSpace,
                  }}
                  placeholder="Free text search"
                />
                <p>Newest first</p>
              </Grid.Column>
            </TitleRow>

            {organizations && organizations.length > 0 && organizations.map(({
              name,
              id,
            }, i) => (
              <Grid.Column key={i}>
                <OrganizationBox onClick={this.selectAsActive(id)}>
                  <h2>{name}</h2>
                </OrganizationBox>
              </Grid.Column>
            )).reverse()}

            <Grid.Column>
              <CreateNewLink to="/create-organization"><h2>Create New</h2></CreateNewLink>
            </Grid.Column>

          </Grid>
        </main>
      </>
    );
  }
}

export default compose(connect((state: StoreState): any => ({
  organizations: selectOrganizations(state),
  isFetching: isFetching(state, 'organization/get'),
})))(Organizations);

const TitleRow = styled(Grid.Row)`
  align-items: center !important;
  margin-bottom: 30px;
`;

const OrganizationBox = styled.div`
  height: 200px;
  background-color: rgb(245, 245, 245);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 3px -1px #bfbebe;
  cursor: pointer;

  &:hover {
    background-color: rgb(226, 226, 226);
  }
`;

const CreateNewLink = styled(NavLink)`
  color: #fff;
  height: 200px;
  background-color: #007ac9;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 3px -1px #bfbebe;
  cursor: pointer;

  &:hover {
    background-color: #0093f2;
  }
`;
