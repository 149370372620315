import axios from 'axios';
import { creditBaseUrl } from '../../util/constants';

export default function createCreditAccount(organizationId: string): any {
  return axios.post(`${creditBaseUrl}/createCreditAccount`, {
    organizationId,
    alertThreshold: 0,
    alertAddresses: [],
  }, {
    withCredentials: true,
  });
}
