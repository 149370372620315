import { Route, Redirect } from 'react-router-dom';
import UserIsNotAuthenticated from '../routes/guards/UserIsNotAuthenticated';
import AuthCallback from './components/AuthCallback';

// @ts-ignore
const Auth = UserIsNotAuthenticated(AuthCallback);

export default [
  <Route key="callback" path="/callback" component={Auth} />,
  <Route key="portal" path="/portal" render={() => <Redirect to="/" />} />,
];
