import { StoreState } from '../state/types';

export function selectLoggedIn(state: StoreState): boolean {
  return state.auth.loggedIn;
}

export function selectUserId(state: StoreState): string | null {
  return state.auth.userId;
}

export function selectIsAdmin(state: StoreState): boolean {
  return state.auth.isAdmin;
}
