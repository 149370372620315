import React, { Component } from 'react';
import styled from 'styled-components';

type State = {
  open: boolean;
};

type Props = {
  title: string;
  subtitle?: string | React.ReactElement<React.ComponentProps<any>, any>;
  children?: React.ReactElement<React.ComponentProps<any>, any>;
};

export default class AccordionItem extends Component<Props, State> {
  state = {
    open: false,
  };

  toggle = () => this.setState((prevState) => ({ open: !prevState.open }));

  render() {
    const {
      state: {
        open,
      },
      props: {
        subtitle,
        title,
        children,
      },
    } = this;
    return (
      <Container>
        <Head aria-expanded={this.state.open} onClick={this.toggle}>
          <h3>{title}</h3>
          <Caret open={open}>
            <div />
            <div />
          </Caret>
        </Head>
        {open && (
        <Body>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          {children}
        </Body>
        )}
      </Container>
    );
  }
}

const Head = styled.a`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  cursor: pointer;

  & > h3 {
    margin: 0;
    max-width: 80%;
    font-family: var(--font-header-medium);
    color: hsl(0deg 0% 0% / 87%);
  }

  & > img {
    margin-left: 15px;
  }
`;

const Body = styled.div`
  padding: 20px 35px 0;
`;

const Subtitle = styled.p`
  font-family: var(--font-header-book);
  font-weight: 600;
`;

const Caret = styled.div`
  position: relative;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: ;
  transform: ${(props: { open: boolean; }) => (props.open ? 'rotate(180deg)' : 'none')};
  & div {
    width: 18px;
    border-radius: 2px;
    height: 3px;
    transform: rotate(135deg);
    background: #007ac9;
    position: absolute;
    left: 11px;

    &:first-child {
      transform: rotate(45deg);
      left: 0;
    }
  }
`;

const Container = styled.div`
  font-family: var(--font-header-light);
  padding-top: 25px;
  border-top: 1px solid #e4e4e4;
  margin-bottom: 25px;

  & > .body > ul {
    margin: 0 0 15px;
    padding: 0;

    & > li {
      margin-bottom: 15px;
    }
  }

  & p > a {
    word-break: break-word;
  }
`;
