import React, { Component } from 'react';
import {
  InjectedFormProps, Field, FieldArray, SubmissionError,
} from 'redux-form';
import { Modal } from 'semantic-ui-react';
import styled from 'styled-components';
import InputField from '../../forms/components/InputField';
import Toast from '../../app/components/Toast';
import { asForm, runSaga } from '../../util';
import { StoreState } from '../../state/types';
import HslButton from '../../forms/components/HslButton';
import { selectActiveOrgId } from '../../organization/selectors';
import { selectRecipients, selectCreditAccount } from '../selectors';
import EmailFieldArray from './EmailFieldArray';
import { CreditAccount } from '../types';
import updateCreditAccount from '../sagas/updateCreditAccount';
import RedbellSvg from '../../static/icons/redbell.svg';

type Props = {
  submitErrors: any;
  initWithValues: () => {};
  initialValues: CreditAccount;
  handleSubmit: (values: any) => React.FormEventHandler<HTMLFormElement>;
};

class CreditAlertForm extends Component<InjectedFormProps<Props, CreditAccount> & Props, {
  modalOpen: boolean;
}> {
  state = {
    modalOpen: false,
  };

  onSubmitAlertAddresses = async ({
    alertAddresses,
    alertThreshold,
  }: CreditAccount) => {
    try {
      // Filter out all null and empty addresses
      const filteredAlertEmails = alertAddresses.filter((a) => !!a !== false && a !== null);
      const updateFields = {
        alertAddresses: filteredAlertEmails,
        alertThreshold: Number(alertThreshold),
      };
      await runSaga(updateCreditAccount, updateFields);
      this.handleClose();
    } catch (e) {
      throw new SubmissionError({
        general: 'Updating credit account info failed',
      });
    }
  };

  onSubmitReportingAddress = async ({
    reportEmail,
  }: CreditAccount) => {
    try {
      const updateFields = {
        reportEmail,
      };
      // credit/reportOperatorTransactionsJob excepts it to be null if not set
      if (!reportEmail) updateFields.reportEmail = null;
      await runSaga(updateCreditAccount, updateFields);
    } catch (e) {
      throw new SubmissionError({
        general: 'Updating credit account info failed',
      });
    }
  };

  handleOpen = () => this.setState({
    modalOpen: true,
  });

  handleClose = () => this.setState({
    modalOpen: false,
  });

  render() {
    const {
      invalid,
      submitting,
      submitErrors,
      initWithValues,
      submitFailed,
      submitSucceeded,
      handleSubmit,
    } = this.props;

    // below is just to check that if string comes from Modify Credit account, its parsed to array and does not crash front
    let addressMap: string[] = []; // default empty array

    // if prop exist we use it
    if (this.props.initialValues.alertAddresses.map !== undefined) {
      addressMap = this.props.initialValues.alertAddresses;
    }

    // if props empty but non-array string with props, we split it to array
    if (addressMap.length === 0 && this.props.initialValues.alertAddresses.length > 0) {
      addressMap = (this.props.initialValues.alertAddresses as unknown as string).split(',');
    }

    return (
      <>
        <KpiContainer>
          <h2>Set low balance alert</h2>
          <KpiContentDivider>
            <BellIcon src={RedbellSvg} alt="notification" />
            <KpiContent>

              {this.props.initialValues.alertThreshold ? (
                <p>
                  Below
                  <KpiThresholdValue>
                    {this.props.initialValues.alertThreshold.toLocaleString('fi-FI')}
                    {' '}
                    €
                  </KpiThresholdValue>
                </p>
              ) : <p>No alert threshold set</p>}

              {addressMap.map((address: string, i: number) => (
                <p key={i}>
                  {address || 'No recipients set'}
                </p>
              ))}

              <KpiEditLink onClick={this.handleOpen}>Edit</KpiEditLink>
            </KpiContent>
          </KpiContentDivider>
        </KpiContainer>
        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic size="small">

          <AlertAddressesForm onSubmit={handleSubmit(this.onSubmitAlertAddresses)}>

            <Modal.Content>
              <p>Send email if balance goes below</p>
              <AlertThresholdContainer>
                { /* @ts-ignore */ }
                <ThresholdInputField id="threshold" name="alertThreshold" type="number" step="1" component={InputField} />
                <EurSuffix>€</EurSuffix>
              </AlertThresholdContainer>

              { /* @ts-ignore */ }
              <FieldArray name="alertAddresses" component={EmailFieldArray} />

            </Modal.Content>
            <hr />

            <ActionsContainer>

              <HslButton inverse onClick={this.handleClose} disabled={submitting} type="button">
                Cancel
              </HslButton>

              <HslButton loading={submitting} disabled={submitting || invalid} type="submit">
                Save
              </HslButton>

            </ActionsContainer>
          </AlertAddressesForm>
        </Modal>

        <ReportEmailForm onSubmit={handleSubmit(this.onSubmitReportingAddress)}>
          <KpiContainer>
            <h2>Set email for reporting</h2>
            <p>Subscribe to monthly excel reports on all transactions in your account (“OpenMaaS wallet”) by adding the email address of the recipient of the report here.</p>
            <AlertThresholdContainer>
              <Field id="reportEmail" name="reportEmail" type="email" step="1" component={InputField} />
            </AlertThresholdContainer>
            <HslButton loading={submitting} disabled={submitting || invalid} type="submit">
              Save
            </HslButton>
          </KpiContainer>
        </ReportEmailForm>

        <Toast error={submitFailed} message={submitErrors.general || (submitSucceeded && 'Credit account info updated')} onClose={initWithValues} />
      </>
    );
  }
}

const mapStateToProps = (state: StoreState) => {
  const organizationId = selectActiveOrgId();
  return {
    initialValues: {
      ...selectCreditAccount(state, organizationId),
      alertAddresses: selectRecipients(state, organizationId) || [''],
    },
  };
};

export default asForm('alertAddressesForm', {
  mapStateToProps,
})(CreditAlertForm);

const AlertAddressesForm = styled.form`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;
  padding: 50px;
  max-width: 550px;
  font-size: 13pt;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ReportEmailForm = styled.form`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;
  max-width: 550px;
  font-size: 13pt;
  padding-top: 20px;

& p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ThresholdInputField = styled(Field)`
  width: 150px;
`;

const AlertThresholdContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const EurSuffix = styled.p`
  margin-left: 10px;
  font-size: large;
`;

const ActionsContainer = styled(Modal.Actions)`
  padding-top: 30px;
  text-align: center;
`;

const KpiContainer = styled.div`
  text-align: initial;
  background: rgb(245, 245, 245);
  padding: 20px 30px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
`;

const KpiContentDivider = styled.div`
  display: flex;
`;

const KpiContent = styled.div`
  padding-top: 10px;
  padding-left: 25px;

  & > p {
    & > strong {

      font-family: Lato, sans-serif;
    }

    margin-bottom: 5px;
  }
`;

const KpiThresholdValue = styled.span`
  padding-left: 5px;
  font-weight: 900;
  color: #de085e;
`;

const KpiEditLink = styled.a`
  cursor: pointer;
`;

const BellIcon = styled.img`
  align-self: baseline;
  width: 40px;
  margin-top: 10px;
`;
