/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import styled from 'styled-components';
import DateRangePicker from './DateRangePicker';
import HslButton from '../../forms/components/HslButton';
import Modal from '../../app/components/Modal';

type P = {
  triggerContent: React.ReactNode | string;
  initialFrom?: string;
  initialTo?: string;
  resetRange: () => void;
  applyRange: (payload: {
    startDate: string;
    endDate: string;
  }) => any;
};

type S = {
  modalOpen: boolean;
};

class DatePickerModal extends Component<P, S> {
  state = {
    modalOpen: false,
  };

  handleOpen = () => this.setState({
    modalOpen: true,
  });

  handleClose = () => this.setState({
    modalOpen: false,
  });

  applyRange = (payload: {
    startDate: string;
    endDate: string;
  }) => {
    this.props.applyRange(payload);
    this.handleClose();
  };

  render() {
    return (
      <Modal
        size="small"
        open={this.state.modalOpen}
        onClose={this.handleClose}
        trigger={<HslButtonCustom onClick={this.handleOpen}>{this.props.triggerContent}</HslButtonCustom>}
      >
        <DateRangePicker {...this.props} applyRange={this.applyRange} />
      </Modal>
    );
  }
}

export default DatePickerModal;

const HslButtonCustom = styled(HslButton)`
  white-space: nowrap;
  padding: 0 !important;
`;
