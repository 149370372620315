import styled from 'styled-components';

type Props = {
  icon: string;
  headingText: string;
  children: React.ReactNode;
};

const ContentBlock = ({
  icon,
  headingText,
  children,
}: Props) => (
  <Container>
    <div>
      <Icon alt="icon" src={icon} />
    </div>
    <Content>
      <h2>{headingText}</h2>
      {children}
    </Content>
  </Container>
);

export default ContentBlock;

const Icon = styled.img`
  width: 50px;
  margin-right: 24px;
`;

const Container = styled.div`
  display: flex;
  margin-top: 50px;
  flex-wrap: wrap;
`;

const Content = styled.div`
  width: 85%;
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
`;
