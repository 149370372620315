import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Segment } from 'semantic-ui-react';
import { selectApiKeys, selectActiveOrgId, selectOrganization } from '../selectors';
import { isFetching } from '../../requests/selectors';
import type { ApiKey } from '../types';
import { runSaga } from '../../util';
import requestApiKey from '../sagas/requestApiKey';
import revokeApiKey from '../sagas/revokeApiKey';
import Toast from '../../app/components/Toast';
import ApiKeyRow from './ApiKeyRow';
import RequestApiKeyModal from './RequestApiKeyModal';
import type { StoreState } from '../../state/types';

type Props = {
  allowedProdApiKey?: boolean | null;
  organizationId?: string | null;
  isRequesting?: boolean;
  isRevoking?: (apiKey: string) => boolean;
  apiKeys?: ApiKey[];
  contractApis?: string[] | null;
  isFetching?: boolean;
};
const initialState = {
  newKeyLabel: '',
  submitMsg: undefined,
  submitFailed: false,
};

class ApiKeyList extends Component<Props, {
  newKeyLabel: string;
  submitFailed: boolean;
  submitMsg: string | null | undefined;
}> {
  static defaultProps = {
    apiKeys: [],
  };

  state = initialState;

  revoke = (apiKey: ApiKey) => async () => {
    try {
      await runSaga(revokeApiKey, apiKey.id, this.props.organizationId);
      this.setState({
        submitMsg: `API Key: ${apiKey.label} revoked`,
      });
    } catch (e) {
      this.setState({
        submitFailed: true,
        submitMsg: `Revoking API Key: ${apiKey.label} failed`,
      });
    }
  };

  initState = () => this.setState(initialState);

  requestSandbox = () => {
    runSaga(requestApiKey, this.props.organizationId, true);
  };

  render() {
    if (this.props.isFetching) {
      return (
        <Segment loading>
          <h1 style={{
            color: 'black',
          }}
          >
            Loading...
          </h1>
        </Segment>
      );
    }

    return (
      <Grid container centered>
        <Grid.Row>
          <Grid.Column verticalAlign="middle" textAlign="left" mobile={16} tablet={16} computer={16}>
            <h1>API Keys</h1>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>

          <Grid.Column verticalAlign="middle" textAlign="left" mobile={16} tablet={16} computer={4}>
            <h3>ID</h3>
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="center" mobile={16} tablet={16} computer={4}>
            <h3>Label</h3>
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="center" mobile={16} tablet={16} computer={4}>
            <h3>Environment</h3>
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="right" mobile={16} tablet={16} computer={4} />

        </Grid.Row>

        {this.props.apiKeys?.map((apiKey, i) => <ApiKeyRow key={i} apiKeyId={apiKey.id} handleRevoke={this.revoke(apiKey)} />)}

        <Grid.Column verticalAlign="middle" textAlign="left" mobile={16} tablet={16} computer={8}>
          {this.props.allowedProdApiKey && <RequestApiKeyModal />}
        </Grid.Column>

        <Grid.Column verticalAlign="middle" textAlign="right" mobile={16} tablet={16} computer={8}>
          <RequestApiKeyModal sandbox />
        </Grid.Column>

        <Toast error={this.state.submitFailed} message={this.state.submitMsg} onClose={this.initState} />
      </Grid>
    );
  }
}

export default compose(connect((state: StoreState): Props => {
  const organizationId = selectActiveOrgId();
  const organization = selectOrganization(state, organizationId);
  const allowedProdApiKey: boolean | null | undefined = organization?.contractApis?.includes('public');
  return {
    allowedProdApiKey,
    organizationId,
    isRequesting: isFetching(state, 'apiKey/request'),
    apiKeys: selectApiKeys(state, selectActiveOrgId()),
    isRevoking: (id) => isFetching(state, `apiKey/revoke/${id}`),
  };
}))(ApiKeyList);
