import axios from 'axios';
import { authBaseUrl } from '../../util/constants';

export default function removeUser(body: {
  userId: string;
  organizationId: string;
}) {
  return axios.put(`${authBaseUrl}/removeUser`, body, {
    withCredentials: true,
  });
}
