import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { runSaga } from '../../util';
import confirmCard from '../sagas/confirmCard';

type Props = {
  match: {
    params: {
      id: string;
    }
  }
};

class CardCallback extends React.Component<RouteComponentProps & Props, any> {
  componentDidMount() {
    if (this.props.match.params.id) {
      runSaga(confirmCard, this.props.match.params.id);
    }
    this.props.history.replace('/organization');
  }

  render() {
    return null;
  }
}

export default CardCallback;
