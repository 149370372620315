import { WrappedFieldArrayProps, Field } from 'redux-form';
import styled from 'styled-components';
import InputField from '../../forms/components/InputField';
import { required, minLength4, validateEmail } from '../../forms/helpers';
import CloseSvg from '../../static/icons/close.svg';

const EmailFieldArray = ({
  fields,
}: WrappedFieldArrayProps): React.ReactElement<React.ComponentProps<any>, any> => (
  <div>
    <RecipientsHeader>
      <p onClick={() => fields.push('')}>
        Recipient(s)
      </p>
      <a onClick={() => fields.push('')}>
        Add new
      </a>
    </RecipientsHeader>
    {fields.map((field: any, i: any) => (
      <EmailInputContainer key={`email${i}`}>
        { /* @ts-ignore */ }
        <EmailInput id={`email${i}`} type="email" name={field} placeholder="Recipient Email" component={InputField} validate={[required, minLength4, validateEmail]} />
        <EmailInputSuffix src={CloseSvg} onClick={() => fields.remove(i)} alt="close" />
      </EmailInputContainer>
    ))}
  </div>
);

export default EmailFieldArray;

const RecipientsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0 20px;

  & > a {
    cursor: pointer;
  }

  & > p {
    margin: 0;
  }
`;

const EmailInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  width: 100%;
  margin-right: 10px;
`;

const EmailInputSuffix = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0;
  margin-right: 15px;
  margin-top: 35px;
`;

const EmailInput = styled(Field)`
  margin-top: 0 !important;
`;
