/* eslint-disable import/no-cycle */
import { stringify } from 'query-string';
import { randomBytes } from 'crypto';
import { openIdConnectClientId, openIdConnectUrl, authCallbackUrl } from './constants';

type Params = {
  customState?: string;
  redirectUri?: string;
};

export default function generateLoginUrl({
  customState,
  redirectUri,
}: Params = {}): string {
  let state = customState || localStorage.getItem('state');

  if (!state) {
    state = randomBytes(16).toString('hex');
    localStorage.setItem('state', state);
  }

  const queryParams = stringify({
    response_type: 'code',
    scope: 'openid',
    client_id: openIdConnectClientId,
    state,
    redirect_uri: redirectUri || authCallbackUrl,
  });
  return `${openIdConnectUrl}/openid/auth?${queryParams}`;
}
