/* eslint-disable react/jsx-props-no-spreading */
import { Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { Meta } from '../types';

type Props = {
  id: string;
  input: Record<string, any>;
  type?: string;
  placeholder?: string;
  className?: StyleSheet;
  label?: string;
  meta?: Meta;
  disabled?: boolean;
  value?: string;
};

const InputField = ({
  input,
  placeholder,
  type,
  id,
  label,
  meta,
  disabled,
}: Props) => (
  <Container>
    {label && id && <label htmlFor={id}>{label}</label>}

    <HslInput {...input} disabled={!!disabled} id={id} placeholder={placeholder} type={type} />

    {meta?.touched && ((meta.error && <Message error>{meta.error}</Message>) || (meta.warning && <Message warning>{meta.warning}</Message>))}
  </Container>
);

export default InputField;

const Container = styled.div`
  width: 100%;
`;

const HslInput = styled.input`
  border-radius: 4px !important;
  border: 1px solid #666;
  padding: 12px 0 18px 12px;
  display: block;
  margin: 1.4rem 0 !important;
  font-family: var(--font-header-book) !important;
  color: var(--hsl-input) !important;
  width: 100%;
  height: 50px;
  &:disabled {
    color: #666 !important;
    background-color: unset;
  }
`;
