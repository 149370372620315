import axios, { AxiosResponse } from 'axios';
import { stringify } from 'query-string';
import { creditBaseUrl } from '../../util/constants';

export default async function fetchOrders(startDate: string, endDate: string, organizationId: string) {
  const qs = stringify({
    startDate,
    endDate,
    organizationId,
    reportFormat: 'json',
  });
  const {
    data,
  }: AxiosResponse = await axios.get(`${creditBaseUrl}/getTransactionsReport?${qs}`, {
    withCredentials: true,
  });
  return data;
}
