import axios from 'axios';
import type { UserResponse } from '../types';
import { authBaseUrl } from '../../util/constants';

export default async function fetchUser(): Promise<any> {
  const URL = `${authBaseUrl}/user`;
  const {
    data,
  }: {
    data: {
      user: UserResponse;
    };
  } = await axios.get(URL, {
    withCredentials: true,
  });
  return data;
}
