import axios from 'axios';
import { creditBaseUrl } from '../../util/constants';

export default async function fetchCreditAccount(organizationId: string): Promise<any> {
  const {
    data,
  } = await axios.get(`${creditBaseUrl}/GetCreditAccount?organizationId=${organizationId}`, {
    withCredentials: true,
  });
  return data;
}
