import React, { Component } from 'react';
import { Modal, Grid, Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import type { StoreState } from '../../state/types';
import HslButton from '../../forms/components/HslButton';
import type { ApiKey } from '../types';
import { selectApiKey } from '../selectors';
import { isFetching } from '../../requests/selectors';
import CloseSvg from '../../static/icons/close.svg';

type Props = {
  apiKeyId: string;
  apiKey?: ApiKey | null;
  handleRevoke: (...args: Array<any>) => any;
  isRevoking?: boolean;
};

class ApiKeyRow extends Component<Props, {
  modalOpen: boolean;
}> {
  state = {
    modalOpen: false,
  };

  handleOpen = () => this.setState({
    modalOpen: true,
  });

  handleClose = () => this.setState({
    modalOpen: false,
  });

  handleRevoke = async () => {
    this.handleClose();
    await this.props.handleRevoke();
  };

  render() {
    if (!this.props.apiKey) return null;
    const {
      id,
      types,
      label,
    } = this.props.apiKey;
    return (
      <>
        <StyledRow>

          <Grid.Column verticalAlign="middle" textAlign="left" mobile={16} tablet={16} computer={4}>
            {id}
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="center" mobile={16} tablet={16} computer={4}>
            {label}
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="center" mobile={16} tablet={16} computer={4}>
            {types ? types.includes('sandbox') ? 'Sandbox' : 'Production' : ''}
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="right" mobile={16} tablet={16} computer={4}>
            {this.props.isRevoking ? <Loader active inline size="small" /> : <RevokeLink alt="icon" src={CloseSvg} onClick={this.handleOpen} />}

          </Grid.Column>

        </StyledRow>

        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic size="small">

          <ModalContainer>
            <Modal.Content>
              <h2>You are about to revoke an API key.</h2>
              <ModalContent>
                Revoking an API Key is final. Once revoked, it cannot be restored.
                <br />
                <br />
                <Strong>You are about to revoke the following API Key:</Strong>
                <br />
                <br />
                <Strong>ID:</Strong>
                {' '}
                {this.props.apiKeyId}
                <br />
                <Strong>Label:</Strong>
                {' '}
                {this.props.apiKey.label}

              </ModalContent>

            </Modal.Content>
            <hr />

            <ModalActions>

              <HslButton
                inverse
                half
                onClick={this.handleClose}
                type="button"
              >
                Cancel
              </HslButton>

              <HslButton
                color="red"
                half
                onClick={this.handleRevoke}
              >
                Revoke
              </HslButton>

            </ModalActions>
          </ModalContainer>
        </Modal>
      </>
    );
  }
}

export default connect((state: StoreState, {
  apiKeyId,
}: {
  apiKeyId: string;
}) => {
  const apiKey = selectApiKey(state, apiKeyId) || null;
  return {
    apiKey,
    isRevoking: isFetching(state, `apiKey/revoke/${apiKeyId}`),
  };
})(ApiKeyRow);

const StyledRow = styled(Grid.Row)`
  border-top: 1px solid #dadada;
  min-height: 55px;
`;

const Strong = styled.strong`
  font-family: Lato, sans-serif;
`;

const ModalContainer = styled.div`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;
  padding: 50px;
  max-width: 550px;
  font-size: 13pt;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ModalContent = styled.div`
  padding: 30px 0 20px;
`;

const ModalActions = styled(Modal.Actions)`
  display: flex;
  padding-top: 30px;
  text-align: center;
`;

const RevokeLink = styled.img`
  cursor: pointer;
`;
