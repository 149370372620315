import axios from 'axios';
import type { ApiKey } from '../types';
import { authBaseUrl } from '../../util/constants';

type R = {
  data: ApiKey;
};

export default async function requestApiKey(organizationId: string, label: string, sandbox: boolean): Promise<Record<string, ApiKey>> {
  const {
    data,
  }: R = await axios.post(`${authBaseUrl}/requestApiKey`, {
    organizationId,
    label,
    sandbox,
  }, {
    withCredentials: true,
  });
  return {
    ...data,
    // @ts-ignore
    organizationId,
  };
}
