import axios from 'axios';
import type { UpdateOrganizationBody, RawOrganization, Organization } from '../types';
import { authBaseUrl } from '../../util/constants';

export default async function updateOrganization(orgId: string, body: UpdateOrganizationBody): Promise<Record<string, Organization>> {
  const {
    data: {
      organizationId: id,
      organizationName: name,
      // @ts-ignore
      userIds,
      apiKeys,
      ...rest
    },
  }: {
    data: RawOrganization;
  } = await axios.put(`${authBaseUrl}/updateOrganization/?organizationId=${orgId}`, body, {
    withCredentials: true,
  });
  return {
    // @ts-ignore
    id,
    // @ts-ignore
    name,
    ...rest,
  };
}
