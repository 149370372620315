import React, { Component } from 'react';
import { AxiosError } from 'axios';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import { connect } from 'react-redux';
import styled from 'styled-components';
import LoaderOverlay from '../../app/components/LoaderOverlay';
import acceptInvite from '../sagas/acceptInvite';
import { publicUrl } from '../../util/constants';
import Toast from '../../app/components/Toast';
import { generateLoginUrl, runSaga } from '../../util';
import { isFetching } from '../../requests/selectors';
import authenticate from '../../auth/sagas/authenticate';
import { selectLoggedIn } from '../../auth/selectors';
import type { StoreState } from '../../state/types';

type Props = {
  isLoggedIn: boolean;
  loggingIn: boolean;
  isFetching?: boolean;
  acceptingInvite: boolean;
  location?: any;
  history?: any;
};

type State = {
  inviteFailed: string;
  inviteSuccess: boolean;
  toastTTL: number;
};

class AcceptInvitePage extends Component<Props, State> {
  state = {
    inviteFailed: '',
    inviteSuccess: false,
    toastTTL: 4,
  };

  interval: NodeJS.Timer | undefined;

  async componentDidMount() {
    const query = parse(this.props.location.search);

    if (!query.state) {
      this.props.history.push('/');
    }

    try {
      if (query.code) {
        await runSaga(authenticate, query.code, `${publicUrl}/invitation`);
      }
      await runSaga(acceptInvite, query.state);
      this.setState({
        inviteSuccess: true,
      });
      this.interval = setInterval(() => (this.state.toastTTL
        ? this.setState((prevState: { toastTTL: number; }) => ({ toastTTL: prevState.toastTTL - 1 }))
        : this.props.history.push('/')), 1000);
    } catch (e) {
      const error = e as AxiosError;
      let errorMessage = error?.response?.data;

      switch (errorMessage) {
        case 'NO_INVITATION_PENDING':
          errorMessage = 'No invitation found for the email address.';
          break;
        case 'INVITE_TOKEN_INVALID':
          errorMessage = 'Invalid token for the invitation.';
          break;
        case 'INVITE_TOKEN_EXPIRED':
          errorMessage = 'Invitation has expired.';
          break;
        default:
          window.location.href = generateLoginUrl({
            customState: query.state as string || '',
            redirectUri: `${publicUrl}/invitation`,
          });
          break;
      }
      this.setState({
        inviteFailed: errorMessage,
      });
    }
  }

  componentWillUnmount() {
    if (this.interval) clearInterval(this.interval);
  }

  render() {
    let loaderMessage;
    const {
      loggingIn,
      acceptingInvite,
    } = this.props;

    if (loggingIn) {
      loaderMessage = 'Authenticating...';
    }

    if (acceptingInvite) {
      loaderMessage = 'Accepting invitation...';
    }

    return (
      <div>
        { this.state.inviteFailed && <FailedMessage> <p>{this.state.inviteFailed}</p> </FailedMessage> }

        <div>
          <LoaderOverlay message={loaderMessage} active={acceptingInvite || loggingIn} />
          { this.state.inviteSuccess && <Toast message={this.state.inviteSuccess ? `Invite successful. Redirecting in ${this.state.toastTTL}` : undefined} /> }
        </div>
      </div>
    );
  }
}

export default compose(withRouter, connect((state: StoreState): Props => ({
  isLoggedIn: selectLoggedIn(state),
  acceptingInvite: isFetching(state, 'organization/acceptInvite::SILENT'),
  loggingIn: isFetching(state, 'authenticate'),
})))(AcceptInvitePage);

const FailedMessage = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
