import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid, Loader } from 'semantic-ui-react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { isFetching } from '../../requests/selectors';
import CreditAlertForm from './CreditAlertForm';
import IncrementCreditBox from './IncrementCreditBox';
import AdminCreditBox from './AdminCreditBox';
import { runSaga } from '../../util';
import { selectActiveOrgId } from '../../organization/selectors';
import { selectCreditAccount } from '../selectors';
import { CreditAccount } from '../types';
import fetchCreditAccount from '../sagas/fetchCreditAccount';
import IncrementsBox from './IncrementsBox';
import SelectorWrapper from '../../auth/wrappers/SelectorWrapper';
import { selectIsAdmin } from '../../auth/selectors';
import type { StoreState } from '../../state/types';

type Props = {
  isFetchingData: boolean;
  creditAccount: CreditAccount | null | undefined;
  organizationId: string | null | undefined;
};
const AdminSection = SelectorWrapper(selectIsAdmin, () => (
  <GridColumn mobile={16} tablet={16} computer={6}>
    { /* @ts-ignore */}
    <AdminCreditBox />
  </GridColumn>
));

class CreditSection extends Component<Props> {
  componentDidMount() {
    if (!this.props.creditAccount) {
      runSaga(fetchCreditAccount);
    }
  }

  render() {
    const {
      creditAccount,
      isFetchingData,
    } = this.props;

    if (!creditAccount || isFetchingData) {
      return (
        <main>
          <Container centered>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={16} computer={7}>
                <CreditSumContainer>
                  {isFetchingData ? <Loader active /> : <h2>No active credit account</h2>}
                </CreditSumContainer>
              </Grid.Column>
            </Grid.Row>
          </Container>
        </main>
      );
    }

    return (
      <main>
        <Container centered>
          {creditAccount.allowNegativeCredit === false && (
          <Grid.Row>
            <Grid.Column mobile={16} tablet={16} computer={7}>
              <CreditSumContainer>
                <h2>Balance of your prepaid account</h2>
                <h1>
                  {new Intl.NumberFormat('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  }).format(creditAccount.credit)}
                </h1>
                <p>{format(new Date(creditAccount.timestamp), 'dd.MM.yyyy')}</p>
              </CreditSumContainer>
            </Grid.Column>
          </Grid.Row>
          )}
          <Grid.Row>
            <AdminSection />
            {!!creditAccount.increments.length && (
            <GridColumn mobile={16} tablet={16} computer={6}>
              <IncrementsBox />
            </GridColumn>
            )}
            {creditAccount.hslCustomerId && creditAccount.referenceCode && (
            <GridColumn mobile={16} tablet={16} computer={6}>
              <IncrementCreditBox />
            </GridColumn>
            )}
            <GridColumn mobile={16} tablet={16} computer={6}>
              { /* @ts-ignore */}
              <CreditAlertForm />
            </GridColumn>
          </Grid.Row>
        </Container>
      </main>
    );
  }
}

export default compose(connect((state: StoreState) => {
  const organizationId = selectActiveOrgId();
  return {
    organizationId,
    isFetchingData: isFetching(state, 'creditAccount/get'),
    creditAccount: selectCreditAccount(state, organizationId),
  };
}))(CreditSection);

const Container = styled(Grid)`
  min-height: 250px;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

const CreditSumContainer = styled.div`
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > h1 {
    font-size: 45pt;
    margin-bottom: 0;
    margin-top: 20px;
  }

  & > h2 {
    font-size: 25pt;
  }

  & > p {
    color: #848484;
  }
`;

const GridColumn = styled(Grid.Column)`
  max-width: 530px;
  margin-bottom: 10px !important;
`;
