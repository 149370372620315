import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import styled from 'styled-components';
import { selectOrganization, selectActiveOrgId } from '../selectors';
import type { Organization, OrganizationUser } from '../types';
import ConfirmRemoveUserModal from './ConfirmRemoveUserModal';
import { selectUserId } from '../../auth/selectors';
import type { StoreState } from '../../state/types';
import CloseSvg from '../../static/icons/close.svg';

type Props = {
  ownId?: string;
  organization?: Organization | null;
};
type State = {
  userToRemove: OrganizationUser | null | undefined;
};

class UserList extends Component<Props, State> {
  state = {
    userToRemove: undefined,
  };

  setUserToRemove = (userToRemove: OrganizationUser) => () => this.setState({
    userToRemove,
  });

  unsetUserToRemove = () => this.setState({
    userToRemove: undefined,
  });

  render() {
    const users = this.props.organization && this.props.organization.users;
    return (
      <Grid container centered>
        <UsersRow>
          <Grid.Column verticalAlign="middle" textAlign="left" mobile={16} tablet={16} computer={16}>
            <h1>Users</h1>
          </Grid.Column>
        </UsersRow>
        {users && users.map((user, i) => (
          <UserRow key={i}>
            <UserRowNameCol mobile={16} tablet={16} computer={8}>
              <p>{user.name}</p>
            </UserRowNameCol>
            <UserRowEmailCol mobile={16} tablet={16} computer={7}>
              <p>{user.email}</p>
            </UserRowEmailCol>
            <UserRowEmailCol mobile={16} tablet={16} computer={1}>
              {user.id !== this.props.ownId && <TriggerLink alt="icon" src={CloseSvg} onClick={this.setUserToRemove(user)} />}
            </UserRowEmailCol>
          </UserRow>
        ))}
        { /* @ts-ignore */}
        <ConfirmRemoveUserModal handleClose={this.unsetUserToRemove} user={this.state.userToRemove} />
      </Grid>
    );
  }
}

export default compose(connect((state: StoreState): any => {
  const ownId = selectUserId(state);
  return {
    organization: selectOrganization(state, selectActiveOrgId() || ''),
    ownId,
  };
}))(UserList);

const UsersRow = styled(Grid.Row)`
  margin-top: 20px;
`;

const UserRow = styled(Grid.Row)`
  border-top: 1px solid #dadada;
`;

const UserRowNameCol = styled(Grid.Column)`
  text-align: start;
`;

const UserRowEmailCol = styled(Grid.Column)`
  text-align: end !important;
`;

const TriggerLink = styled.img`
  cursor: pointer;
`;
