import axios from 'axios';
import { authBaseUrl } from '../../util/constants';
import { SignupFormFields } from '../../forms/types';

export const organizationSignup = async (body: SignupFormFields) => {
  const {
    termsAccepted,
    customerServiceEmail,
    customerServicePhone,
    ...rest
  } = body;
  const reqBody: Record<string, any> = rest;

  if (customerServiceEmail) {
    reqBody.customerServiceEmail = customerServiceEmail;
  }

  if (customerServicePhone) {
    reqBody.customerServicePhone = customerServicePhone;
  }

  const response = await axios.post(`${authBaseUrl}/signup`, body, {
    withCredentials: true,
  });
  return response.data;
};
