/* eslint-disable react/jsx-props-no-spreading */
// @ts-nocheck
import styled from 'styled-components';
import { Field } from 'redux-form';
import { StoreState } from '../../state/types';
import adminUpdateCreditAccount from '../sagas/adminUpdateCreditAccount';
import { asForm, runSaga } from '../../util';
import { selectActiveOrgId } from '../../organization/selectors';
import { selectCreditAccount } from '../selectors';
import InputField from '../../forms/components/InputField';
import { minLength, maxLength } from '../../forms/helpers';
import HslButton from '../../forms/components/HslButton';
import Toast from '../../app/components/Toast';
import ToggleSwitch from '../../forms/components/ToggleSwitch';

const onSubmit = ({
  organizationId,
  hslCustomerId,
  referenceCode,
  allowNegativeCredit,
}: {
  organizationId: string;
  hslCustomerId: number;
  referenceCode: string[];
  allowNegativeCredit: boolean;
}) => {
  runSaga(adminUpdateCreditAccount, {
    organizationId,
    hslCustomerId,
    referenceCode,
    allowNegativeCredit,
  });
};

const maxRefCodeLength = maxLength(20);
const minRefCodeLength = minLength(20);
const requiredIfNotAllowNegative = (value: string | number | boolean, allValues: any) => {
  if (allValues.allowNegativeCredit) return undefined;
  return (value ? undefined : 'Required field');
};

const AdminCreditBox = ({
  handleSubmit,
  submitting,
  submitFailed,
  submitErrors,
  submitSucceeded,
  initWithValues,
  invalid,
  fieldValues,
}: {
  handleSubmit: (values: any) => React.FormEventHandler<HTMLFormElement>;
  submitting?: boolean;
  submitFailed?: boolean;
  submitErrors: any;
  submitSucceeded?: boolean;
  initWithValues: () => {};
  invalid?: boolean;
  fieldValues: any;
}) => (
  <KpiContainer>
    <h2>Administrative settings</h2>
    <form onSubmit={handleSubmit(onSubmit)}>

      <Field
        label="Reference code"
        id="referenceCode"
        name="referenceCode"
        component={InputField}
        validate={[requiredIfNotAllowNegative, minRefCodeLength, maxRefCodeLength]}
        disabled={fieldValues.allowNegativeCredit}
      />
      <Field
        label="HSL customer ID"
        id="hslCustomerId"
        name="hslCustomerId"
        component={InputField}
        validate={[requiredIfNotAllowNegative]}
        disabled={fieldValues.allowNegativeCredit}
      />

      <ToggleSwitch
        name="allowNegativeCredit"
        id="allowNegativeCredit"
        onLabel="Invoice"
        offLabel="Wallet"
      />

      <HslButton loading={submitting} disabled={submitting || invalid} type="submit">
        Submit
      </HslButton>
    </form>
    <Toast error={submitFailed} message={submitErrors.general || (submitSucceeded && 'Updated')} onClose={initWithValues} />
  </KpiContainer>
);

export default asForm('creditInfo', {
  mapStateToProps: (state: StoreState) => ({
    initialValues: selectCreditAccount(state, selectActiveOrgId()),
  }),
})(AdminCreditBox);

const KpiContainer = styled.div`
  height: 100%;
  text-align: initial;
  background: rgb(245, 245, 245);
  padding: 20px 30px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
  #referenceCode:disabled {
    color: rgb(245, 245, 245) !important;
  }
  #hslCustomerId:disabled {
    color: rgb(245, 245, 245) !important;
  }
`;
