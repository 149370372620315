import React from 'react';
import styled from 'styled-components';

type Props = {
  menuOpen: boolean;
  menuHeight: number;
  children: React.ReactNode;
};

const MobileMenu = ({
  menuOpen,
  menuHeight,
  children,
}: Props) => (
  <List menuOpen={menuOpen} menuHeight={menuHeight}>
    { /* @ts-ignore */ }
    {children.map((Child, index) => <li key={index}><Child /></li>)}
  </List>
);

export default MobileMenu;

const List = styled.ul`
  visibility: ${(props: Props) => (props.menuOpen ? 'visible' : 'hidden')};
  height: ${(props: Props) => (props.menuOpen ? props.menuHeight : 0)},
  transition: 'all .15s ease-in-out'
  position: absolute;
  z-index: 9 !important;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: var(--hsl-blue);
  margin: 0;
  padding: 140px 30px 0;

  & > li {
    opacity: ${(props: Props) => (props.menuOpen ? 1 : 0)};
    display: flex;
    align-items: center;
    list-style: none;
    border-bottom: 2px solid rgba(0, 98, 161, 1);
    transition: all 0.15s ease-in-out;

    & a {
      cursor: pointer;
      font-family: var(--font-header-book) !important;
      font-size: 16px !important;
      line-height: 18px !important;
      color: var(--white);
      display: block;
      width: 100%;
      margin: 0;
      padding: 30px;
    }

    & img {
      display: none;
    }
  }

  & > li:first-of-type {
    border-top: 2px solid rgba(0, 98, 161, 1);
  }

  @media (min-width: 992px) {
    display: none !important;
  }
`;
