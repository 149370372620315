import { call } from 'redux-saga/effects';
import type { CreditAccount } from '../types';
import { setState } from '../../util';
import callAdminUpdateCreditAccount from '../api/adminUpdateCreditAccount';
import callUpdateOrganizationPayment from '../api/updateOrganizationPayment';

export default function* adminUpdateCreditAccount(fields: {
  organizationId: string;
  allowNegativeCredit: boolean;
  referenceCode: string[];
  hslCustomerId: number;
}) {
  const allowNegativeCredit = Boolean(fields.allowNegativeCredit);
  const referenceCode = allowNegativeCredit ? '' : fields.referenceCode;
  const hslCustomerId = allowNegativeCredit ? '' : fields.hslCustomerId;

  const paymentMethod = allowNegativeCredit ? 'INVOICE' : 'WALLET';

  // TODO: not critical asof, save organization paymentMethod to state
  yield call(callUpdateOrganizationPayment, fields.organizationId, paymentMethod);

  let updatedAccount: CreditAccount;

  try {
    updatedAccount = yield call(callAdminUpdateCreditAccount, fields.organizationId, {
      allowNegativeCredit,
      referenceCode,
      hslCustomerId,
    });
  } catch (error) {
    // Revert organization incase credit fails
    const revertedPaymentMethod = allowNegativeCredit ? 'WALLET' : 'INVOICE';
    yield call(callUpdateOrganizationPayment, fields.organizationId, revertedPaymentMethod);
    throw error;
  }

  yield setState(['creditAccounts', fields.organizationId], (staleAccount: CreditAccount) => ({
    ...staleAccount,
    ...updatedAccount,
    credit: updatedAccount.credit / 100,
    alertThreshold: updatedAccount.alertThreshold / 100,
  }));
}
