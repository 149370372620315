/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { SubmissionError } from 'redux-form';
import { withRouter } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { compose } from 'redux';
import Navbar from '../../app/components/Navbar';
import OrganizationSignupForm from './OrganizationSignupForm';
import type { SignupFormFields } from '../../forms/types';
import { organizationSignup } from '../../auth/api/organizationSignup';
import createCreditAccount from '../../credit/api/createCreditAccount';
import { asForm } from '../../util';

type Props = {
  history: {
    replace: (...args: Array<any>) => any;
  };
};

class OrganizationSignup extends React.Component<Props> {
  submit = async ({
    customerServiceEmail: csE,
    customerServicePhone: csP,
    ...rest
  }: SignupFormFields) => {
    const reqBody = {
      customerServiceEmail: csE || '',
      customerServicePhone: csP || '',
      ...rest,
    };

    try {
      const {
        organizationId,
      } = await organizationSignup(reqBody);
      await createCreditAccount(organizationId);
      localStorage.setItem('activeOrganization', organizationId);
      this.props.history.replace('/organization');
    } catch (e) {
      throw new SubmissionError({
        general: 'Submission failed. Check that all entered values are valid.',
      });
    }
  };

  render() {
    return (
      <>
        <Navbar />
        <main>
          <Grid container columns={2}>

            <Grid.Row>
              <Grid.Column computer={16}>
                <h1>Create New Organization</h1>
              </Grid.Column>
            </Grid.Row>

          </Grid>

          { /* @ts-ignore */}
          <OrganizationSignupForm {...this.props} submit={this.submit} />
        </main>
      </>
    );
  }
}

export default compose(withRouter, asForm('signup'))(OrganizationSignup);
