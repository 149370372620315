import {
  TransitionablePortal, Segment, Header, Grid,
} from 'semantic-ui-react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { selectApiErrorMessage } from '../../requests/selectors';
import { runSaga } from '../../util';
import clearApiError from '../../requests/sagas/clearApiError';
import type { StoreState } from '../../state/types';

const defaultClose = () => runSaga(clearApiError);

type Props = {
  message?: string | null;
  onClose?: (...args: Array<any>) => any;
  error?: boolean;
};

const Toast = ({
  message,
  error,
  onClose,
}: Props) => (
  <TransitionablePortal open={!!message} onClose={onClose || defaultClose}>
    <Container error={error}>
      <Grid container>
        <Grid.Column mobile={16} tablet={16} computer={14}>
          <Header>{`${error ? 'Error: ' : ''}${message || ''}`}</Header>
        </Grid.Column>
      </Grid>
    </Container>
  </TransitionablePortal>
);

export default connect((state: StoreState, {
  message,
  global,
}: {
  message?: string | null | undefined;
  global?: true;
}): any => ({
  message: global ? selectApiErrorMessage(state) : message,
}))(Toast);

const Container = styled(Segment)`
  position: fixed !important;
  background-color: ${(props: Props) => (props.error ? '#ffe7d1 !important' : '#f1fbee !important')};
  z-index: 1000;
  left: 0;
  bottom: 0;
  width: 100vw;
  top: auto !important;
  padding: 2rem!important;
`;
