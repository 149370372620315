import { put } from 'redux-saga/effects';
/**
 * TODO: Having this as a saga is a bit overkill...
 */

// eslint-disable-next-line func-names
export default function* () {
  yield put({
    type: 'ERROR/CLEAR',
  });
}
