import { Organization } from '../types';
import callRevokeInvitation from '../api/revokeInvitation';
import { callApi, setState } from '../../util';
import { selectActiveOrgId } from '../selectors';

export default function* removeUser(email: string) {
  const body = {
    organizationId: selectActiveOrgId(),
    email,
  };
  yield callApi('user/revokeinvitation', callRevokeInvitation, body);
  yield setState(['organizations', body.organizationId], (org: Organization) => {
    const organization = { ...org };
    organization.pendingInvites = organization.pendingInvites?.filter((invite) => invite.email !== body.email);
    return organization;
  });
}
