/* eslint-disable import/no-cycle */
import generateLoginUrl from './generateLoginUrl';

export const {
  REACT_APP_OPENID_CONNECT_URL: openIdConnectUrl,
  REACT_APP_API_BASE_URL: apiBaseUrl,
  REACT_APP_PAYMENT_PATH: paymentPath,
  REACT_APP_CREDIT_PATH: creditPath,
  REACT_APP_ORDERS_PATH: ordersPath,
  REACT_APP_AUTH_PATH: authPath,
  REACT_APP_OPENID_CONNECT_CLIENT_ID: openIdConnectClientId,
  REACT_APP_PUBLIC_URL: publicUrl,
  REACT_APP_ADMIN_MODE: adminMode,
} = process.env;

export const authBaseUrl = `${apiBaseUrl}/${authPath}`;
export const paymentBaseUrl = `${apiBaseUrl}/${paymentPath}`;
export const ordersBaseUrl = `${apiBaseUrl}/${ordersPath}`;
export const creditBaseUrl = `${apiBaseUrl}/${creditPath}`;
export const authCallbackUrl = `${publicUrl}/callback`;
export const signupUrl = `${generateLoginUrl()}&nur`;
