import axios from 'axios';
import { authBaseUrl } from '../../util/constants';
import { PendingInvite } from '../types';

export default async function inviteUsers(invitees: string[], organizationId: string | null): Promise<PendingInvite[]> {
  const { data: pendingInvites }: { data: PendingInvite[] } = await axios.post(`${authBaseUrl}/sendInviteEmails`, {
    invitees,
    organizationId,
  }, {
    withCredentials: true,
  });

  return pendingInvites;
}
