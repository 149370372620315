/* eslint-disable react/jsx-props-no-spreading */
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';

type Props = {
  wide?: boolean | string;
  half?: boolean | string;
  inverse?: boolean;
};

const HslButton = ({
  wide = false,
  inverse = false,
  half = false,
  ...props
}: Props & Record<string, any>) => {
  if (props.color === 'red') {
    return <HslButtonRed {...props} wide={wide.toString()} half={half.toString()} />;
  }
  if (props.color === 'grey') {
    return <HslButtonGrey {...props} wide={wide.toString()} half={half.toString()} />;
  }
  if (inverse) {
    return <HslButtonBlueInverse {...props} wide={wide.toString()} half={half.toString()} />;
  }
  return <HslButtonBlue {...props} wide={wide.toString()} half={half.toString()} />;
};

export default HslButton;

const HslButtonBase = styled(Button)`
  background: #007ac9 !important;
  color: var(--white) !important;
  font-family: var(--font-header) !important;
  border-radius: 25px !important;
  text-shadow: 0 0 2px #555 !important;
  border: 0 none !important;
  font-size: 18px !important;
  line-height: 46px !important;
  font-weight: 500;
  height: 50px !important;
  margin: 0 10px 10px 0 !important;
  padding: 0 25px !important;
  width: ${(props: Props) => (props.wide === 'true' ? '280.6px' : (props.half === 'true' ? '50%' : '179.5px'))};
`;

const HslButtonBlue = styled(HslButtonBase)`
  background: #007ac9 !important;
  &:hover,
  &:focus {
    color: var(--white) !important;
    background: #0074bf !important;
    background: linear-gradient(to bottom, #0093f2, #0074bf), #0074bf !important;
  }
`;

const HslButtonBlueInverse = styled(HslButtonBase)`
  background: white !important;
  border: 2px solid #007ac9 !important;
  text-shadow: none !important;
  color: #007ac9 !important;
  &:hover,
  &:focus {
    background: #eeeeee !important;
    background: linear-gradient(to bottom, #ffffff, #eeeeee), #eeeeee !important;
  }
`;

const HslButtonRed = styled(HslButtonBase)`
  background: #db2827 !important;
  &:hover,
  &:focus {
    background: #db2827 !important;
    background: linear-gradient(to bottom, #f30928, #db2827), #db2827 !important;
  }
`;

const HslButtonGrey = styled(HslButtonBase)`
  background: #babbbc !important;
  &:hover,
  &:focus {
    background: #babbbc !important;
    background: linear-gradient(to bottom, rgb(229, 230, 231), #babbbc), #babbbc !important;
  }
`;
