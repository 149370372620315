/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable import/no-cycle */
/* eslint-disable func-names */
import {
  call,
  CallEffect,
  put,
  SagaReturnType,
} from 'redux-saga/effects';
import logout from '../auth/sagas/logout';

const generateRequestId: () => number = () => Math.floor(Math.random() * 10000000 + 1);

/**
 *
 * @param {*} requestKey A key to identify the request in Redux. Append '::SILENT' to leave no async errors in case of failure.
 * @param {*} fn
 * @param  {...any} args
 */
export default function callApi<Fn extends (...args: any[]) => any>(
  requestKey: string,
  fn: Fn,
  ...args: Parameters<Fn>): CallEffect<SagaReturnType<Fn>> {
  // @ts-ignore
  return call(function* () {
    const key = generateRequestId();
    // Action type to make the request fail silently, leaving no async errors in Redux.
    const failureType: string = requestKey.includes('::SILENT') ? 'REQUEST/FAILURE::SILENT' : 'REQUEST/FAILURE';

    try {
      yield put({
        type: 'REQUEST/START',
        payload: {
          requestKey,
          key,
        },
      });
      const result: Fn = yield call(fn, ...args);
      yield put({
        type: 'REQUEST/SUCCESS',
        payload: {
          omitKey: key,
        },
      });
      return result;
    } catch (e: any) {
      if (e.response && e.response.status === 401) {
        yield call(logout);
      }

      // console.dir(e);
      yield put({
        type: failureType,
        payload: {
          omitKey: key,
          error: e,
        },
      });
      throw e;
    }
  });
}
