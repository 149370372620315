import type { StoreState } from './types';
import { getAuthStateFromStorage } from '../auth/helpers';

export const initialState: StoreState = {
  transactions: {
    orders: {},
    refundedOrders: {},
    totalSum: 0,
    totalSumVat: 0,
    totalAmount: 0,
    displayNames: {},
  },
  auth: getAuthStateFromStorage(),
  creditAccounts: {},
  organizations: {},
  apiKeys: {},
  cards: {},
  requests: {},
  asyncError: {},
  form: {},
  user: {},
};
