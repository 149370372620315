export { default as portalPrivacyPolicyFI } from '../static/media/HSL_OpenMaaS-rajapinnan_tietosuojaseloste_20190730.pdf';
export { default as portalPrivacyPolicyEN } from '../static/media/HSL_OpenMaaS_API_privacy_statement_20190730.pdf';
export { default as authorisationsUiIntsructionsEN } from '../static/media/HSLOpenMaaSAuthorisation_EN.pdf';
export { default as testUsersEN } from '../static/media/HSL_test_users_for_ Sandbox.pdf';
export { default as checklistFI } from '../static/media/Muistilista HSL OpenMaaS -rajapintasopimusta tekeville_14.1.2021.pdf';
export { default as checklistEN } from '../static/media/Checklist for New HSL OpenMaaS Users_14Jan2021.pdf';
export { default as autoRenewingFI } from '../static/media/HSL_jatkuva_saasto_tilaus_14.1.2021.pdf';
export { default as autoRenewingEN } from '../static/media/HSL_auto_renewing_subscriptions_14.1.2021.pdf';
export { default as cancellationAndRefundEN } from '../static/media/HSL_OpenMaaS_Change__cancellation_and_refund_terms_of_tickets_14.1.2021.pdf';
export { default as cancellingTermsFI } from '../static/media/HSL_OpenMaaS_Lippujen_muutos-_ja_peruutusehdot_14.1.2021.pdf';
export { default as tarmo } from '../static/media/HSL_TARMO_2021.pdf';
export const pricesUrl = 'https://sales-api.hsl.fi/api/ticket/v4/get/prices';
