import callFetchOrganization from '../api/fetchOrganization';
import { setState, callApi } from '../../util';

export default function* fetchOrganization(id?: string) {
  const {
    organizations,
    apiKeys,
  } = yield callApi('organization/get', callFetchOrganization, id);
  yield setState('organizations', (state) => ({
    ...state,
    ...organizations,
  }));
  yield setState('apiKeys', (state) => ({
    ...state,
    ...apiKeys,
  }));
}
