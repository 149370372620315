import { call } from 'redux-saga/effects';
import type { CreditAccount } from '../types';
import { setState } from '../../util';
import callUpdateCreditAccount from '../api/updateCreditAccount';
import { selectActiveOrgId } from '../../organization/selectors';

export default function* updateCreditAccount(fieldsToUpdate: {
  alertAddresses: string[];
  alertThreshold: number;
}) {
  // @ts-ignore
  const organizationId = yield call(selectActiveOrgId);

  if (!organizationId) {
    return;
  }

  if (fieldsToUpdate.alertThreshold) {
    // eslint-disable-next-line no-param-reassign
    fieldsToUpdate.alertThreshold *= 100;
  }

  const updatedAccount: CreditAccount = yield call(callUpdateCreditAccount, organizationId, fieldsToUpdate);
  yield setState(['creditAccounts', organizationId], (staleAccount: CreditAccount) => ({
    ...staleAccount,
    ...updatedAccount,
    credit: updatedAccount.credit / 100,
    alertThreshold: updatedAccount.alertThreshold / 100,
  }));
}
