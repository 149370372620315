import React, { Component } from 'react';
import { Grid } from 'semantic-ui-react';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { parse } from 'query-string';
import { selectOrderUnit, selectRefundedOrderUnit } from '../selectors';
import { DisplayNames, OrderUnit, TicketTypeObj } from '../types';
import CaretSvg from '../../static/icons/caret.svg';
import { StoreState } from '../../state/types';

type P = {
  day: string;
  orderUnit: OrderUnit;
  isRefund: boolean;
  open?: true;
  grey: boolean;
  displayNames: DisplayNames
};

type S = {
  open: boolean;
};

class OrderRow extends Component<P, S> {
  static parseTicketTypeKey = (ticketTypeKey: string): {
    zones: string;
    customerTypeId: string;
    ticketTypeId: string;
    orderType: string;
  /* @ts-ignore */
  } => parse(ticketTypeKey, { parseBooleans: true });

  state = {
    open: false,
  };

  toggle = () => this.setState((prevState) => ({ open: !prevState.open }));

  render() {
    const { orderUnit, isRefund, displayNames } = this.props;

    const amount = isRefund ? orderUnit.amount * -1 : orderUnit.amount;
    const sumWithVat = isRefund ? (orderUnit.sumVat * -1).toFixed(2) : (orderUnit.sumVat).toFixed(2);
    const vat = isRefund ? ((orderUnit.sumVat - orderUnit.sum) * -1).toFixed(2) : (orderUnit.sumVat - orderUnit.sum).toFixed(2);
    const ordered: string = format(new Date(orderUnit.day), 'dd.MM.yy');

    /**
     * Object.values() core function return value is set to Array<mixed> and flow doesn't
     * recognize that TicketTypeObj[] matches with it.
     * https://github.com/facebook/flow/issues/2133
     */
    const tickets: TicketTypeObj[] = Object.values(orderUnit.tickets);
    return (
      <Grid>
        <TotalsRow onClick={this.toggle}>
          <Grid.Column verticalAlign="middle" mobile={16} tablet={16} computer={4}>
            <ColumnContent>
              <h4>Date:</h4>
              <Attributes>{ordered}</Attributes>
            </ColumnContent>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={16} tablet={16} computer={4}>
            <ColumnContent>
              <h4>Amount:</h4>
              <Attributes>
                {' '}
                {amount}
              </Attributes>
            </ColumnContent>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={16} tablet={16} computer={4}>
            <ColumnContent>
              <h4>Sum:</h4>
              <Attributes>
                {' '}
                {sumWithVat}
                {' '}
                €
              </Attributes>
            </ColumnContent>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={16} tablet={16} computer={3}>
            <ColumnContent>
              <h4>VAT </h4>
              <Attributes>
                {' '}
                {vat}
                {' '}
                €
              </Attributes>
            </ColumnContent>
          </Grid.Column>
          <Grid.Column verticalAlign="middle" mobile={16} tablet={16} computer={1}>
            { /* @ts-ignore */}
            <CaretIcon src={CaretSvg} open={this.props.open} alt="caret" />
          </Grid.Column>
        </TotalsRow>
        {this.state.open && (
        <ByTicketTypeHeading grey={this.props.grey}>
          <Grid.Column>
            <p>Orders by ticket type</p>
          </Grid.Column>
        </ByTicketTypeHeading>
        )}
        {this.state.open && tickets.map((ticketType, i) => {
          const {
            zones,
            customerTypeId,
            orderType,
          } = OrderRow.parseTicketTypeKey(ticketType.id);

          const { displayName } = ticketType;
          const ticketTypeAmount = isRefund ? ticketType.amount * -1 : ticketType.amount;
          const ticketTypeVatPercent = ticketType.vat;
          // const ticketPrice = isRefund ? (ticketType.priceWithoutVat * -1).toFixed(2) : ticketType.priceWithoutVat.toFixed(2);
          const ticketPriceWithVat = isRefund ? (ticketType.priceWithVat * -1).toFixed(2) : ticketType.priceWithVat.toFixed(2);
          const ticketTypeTotalVat = isRefund ? ((ticketType.priceWithVat - ticketType.priceWithoutVat) * -1).toFixed(2) : (ticketType.priceWithVat - ticketType.priceWithoutVat).toFixed(2);

          return (
            <ByTicketTypeRow key={i} grey={this.props.grey}>
              <Grid.Column key={`${i}0`} mobile={16} tablet={16} computer={4}>
                <ColumnContentOpen>
                  <h4>Attributes:</h4>
                  <Attributes>
                    Zones: {zones}
                  </Attributes>
                  <Attributes>
                    Ticket type:
                    {' '}
                    { /* @ts-ignore */}
                    {displayName}
                  </Attributes>
                  <Attributes>
                    Customer type:
                    {' '}
                    { /* @ts-ignore */}
                    {displayNames[customerTypeId]}
                  </Attributes>
                  <Attributes>
                    Order type:
                    {' '}
                    { /* @ts-ignore */}
                    {displayNames[orderType]}
                  </Attributes>
                </ColumnContentOpen>
              </Grid.Column>
              <Grid.Column key={`${i}1`} mobile={16} tablet={16} computer={4}>
                <ColumnContentOpen>
                  <h4>Amount:</h4>
                  <Attributes>{ticketTypeAmount}</Attributes>
                </ColumnContentOpen>
              </Grid.Column>
              <Grid.Column key={`${i}2`} mobile={16} tablet={16} computer={4}>
                <ColumnContentOpen>
                  <h4>Sum:</h4>
                  <Attributes>
                    {ticketPriceWithVat}
                    {' '}
                    €
                  </Attributes>
                </ColumnContentOpen>
              </Grid.Column>
              <Grid.Column key={`${i}3`} mobile={16} tablet={16} computer={4}>
                <ColumnContentOpen>
                  <h4>VAT {ticketTypeVatPercent.toString()}%</h4>
                  <Attributes>
                    {ticketTypeTotalVat}
                    {' '}
                    €
                  </Attributes>
                </ColumnContentOpen>
              </Grid.Column>
            </ByTicketTypeRow>
          );
        })}
      </Grid>
    );
  }
}

export default compose(connect((state: StoreState, {
  // @ts-ignore
  day,
  // @ts-ignore
  isRefund,
}): any => ({
  // @ts-ignore
  orderUnit: isRefund ? selectRefundedOrderUnit(state, day) : selectOrderUnit(state, day),
})))(OrderRow);

const ByTicketTypeRow = styled(Grid.Row)`
  border-top: ${(props: P) => (props.grey ? '3px solid white' : '2px solid #f8f8f8')};
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
  padding-left: 2rem !important;
`;

const ByTicketTypeHeading = styled(Grid.Row)`
  padding: 10px;
  border-top: ${(props: P) => (props.grey ? '3px solid white' : '2px solid #f8f8f8')};
  padding-left: 2rem !important;
`;

const TotalsRow = styled(Grid.Row)`
  cursor: pointer;
`;

const CaretIcon = styled.img`
  margin: ${(props: P) => (props.open ? '10px 0' : '0')};
  transform: rotate(180deg);
`;

const Attributes = styled.p`
  text-align: left;
  margin-bottom: 3px;
`;

const ColumnContent = styled.div`
  padding-top: 10px;
`;

const ColumnContentOpen = styled.div`
  padding-top: 4px;
  font-size: 12px;
`;
