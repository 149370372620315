import React, { Component } from 'react';
import styled from 'styled-components';

// Why does this exist? Because semantic-ui-react's Modal sucks really hard.
// This is a simple, easy to use, functioning modal with no useless global CSS to always override.
// Use this instead of the sematic ui modal unless you love weird CSS problems.

type Props = {
  size?: string;
  trigger: React.ReactNode;
  open: boolean;
  children: React.ReactNode;
  onClose: () => any;
};

export default class Modal extends Component<Props, {}> {
  onBackdropClick = () => {
    this.props.onClose();
  };

  onContainerClick = (e: Event) => {
    e.stopPropagation();
  };

  render = () => (
    <>
      {this.props.trigger}
      {this.props.open && /* @ts-ignore */ (
      <Backdrop onClick={this.onBackdropClick}>
        { /* @ts-ignore */}
        <Container onClick={this.onContainerClick}>
          {this.props.children}
        </Container>
      </Backdrop>
      )}
    </>
  );
}

const Backdrop = styled.div`
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
`;

const Container = styled.div`
  display: flex;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  .visible.transition {
    margin-top: auto !important;
    display: inline-block !important;
    position: relative;
    top: 20%;
  }
`;
