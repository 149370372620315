import React, { FC, useEffect } from 'react';
import {
  Checkbox,
  Form,
  Grid,
} from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { Filters } from '../types';

type Props = {
  filters: Filters,
};

const INCOMING_FILTERS = 'INCOMING_FILTERS';
const FILTERING_STATUS = 'FILTERING_STATUS';
const PAYMENT_METHOD = 'PAYMENT_METHOD';
const PRODUCTION_ACCESS = 'PRODUCTION_ACCESS';

export const reducer = (state: any, action: any) => {
  const history = useHistory();

  switch (action.type) {
    case INCOMING_FILTERS: {
      return {
        ...action.filters,
      };
    }
    case FILTERING_STATUS: {
      if (action.filteringStatus.checked === false) {
        history.push('/organizations?filter=false&paymentMethod=all&productionAccess=false');
      } else if (action.filteringStatus.checked === true) {
        history.push('/organizations?filter=true&paymentMethod=all&productionAccess=false');
      }
      return {
        ...state,
      };
    }
    case PAYMENT_METHOD: {
      switch (action.selectedPaymentMethod) {
        case 'all':
          history.push(`/organizations?filter=true&paymentMethod=all&productionAccess=${state.productionAccess}`);
          break;
        case 'invoice':
          history.push(`/organizations?filter=true&paymentMethod=invoice&productionAccess=${state.productionAccess}`);
          break;
        case 'wallet':
          history.push(`/organizations?filter=true&paymentMethod=wallet&productionAccess=${state.productionAccess}`);
          break;
        default:
          throw new Error('Filtering: invalid paymentMethod');
      }

      return {
        ...state,
      };
    }
    case PRODUCTION_ACCESS: {
      if (action.production.checked === false) {
        history.push(`/organizations?filter=true&paymentMethod=${state.selectedPaymentMethod}&productionAccess=false`);
      } else if (action.production.checked === true) {
        history.push(`/organizations?filter=true&paymentMethod=${state.selectedPaymentMethod}&productionAccess=true`);
      }

      return {
        ...state,
      };
    }
    default:
      return state;
  }
};

export const Filtering: FC<Props> = ({ filters }) => {
  const [state, dispatch] = React.useReducer(reducer, filters);

  useEffect(() => {
    dispatch({ type: INCOMING_FILTERS, filters });
  }, [dispatch, filters]);

  return (
    <div>
      <Grid>
        <Grid.Row>
          <Grid.Column width={2}>
            <Checkbox
              label={<label htmlFor="filtering">Enable filtering</label>}
              onChange={(e, filteringStatus) => dispatch({ type: FILTERING_STATUS, filteringStatus })}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Form>
              <Form.Field disabled={!state.enabled}>
                Payment Method
              </Form.Field>
              <Form.Field>
                <Checkbox
                  disabled={!state.enabled}
                  radio
                  label="All"
                  value="all"
                  name="paymentMethodRadioGroup"
                  checked={state.selectedPaymentMethod === 'all'}
                  onChange={(e, selected) => dispatch({ type: PAYMENT_METHOD, selectedPaymentMethod: selected.value })}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  disabled={!state.enabled}
                  radio
                  label="Invoice"
                  value="invoice"
                  name="paymentMethodRadioGroup"
                  checked={state.selectedPaymentMethod === 'invoice'}
                  onChange={(e, selected) => dispatch({ type: PAYMENT_METHOD, selectedPaymentMethod: selected.value })}
                />
              </Form.Field>
              <Form.Field>
                <Checkbox
                  disabled={!state.enabled}
                  radio
                  label="Wallet"
                  value="wallet"
                  name="paymentMethodRadioGroup"
                  checked={state.selectedPaymentMethod === 'wallet'}
                  onChange={(e, selected) => dispatch({ type: PAYMENT_METHOD, selectedPaymentMethod: selected.value })}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column width={3}>
            <Checkbox
              disabled={!state.enabled}
              label={<label htmlFor="prodAccess">Production access only</label>}
              checked={state.productionAccess}
              onChange={(e, production) => dispatch({ type: PRODUCTION_ACCESS, production })}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
