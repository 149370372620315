/* eslint-disable react/jsx-props-no-spreading */
import { Field } from 'redux-form';
import './toggle.css';

type InputType = JSX.IntrinsicAttributes & React.ClassAttributes<HTMLInputElement> & React.InputHTMLAttributes<HTMLInputElement>;

type Props = {
  id: string;
  name: string;
  onLabel: string;
  offLabel: string;
};

const ToggleSwitch = ({
  id,
  name,
  onLabel,
  offLabel,
}: Props) => (
  <div>
    <label className="toggle" htmlFor={name}>
      <Field
        name={name}
        id={id}
        component={(props: { input: InputType }) => <input {...props.input} id={id} type="checkbox" />}
        type="checkbox"
      />
      <span className="slider" />
      <span className="labels" data-on={onLabel} data-off={offLabel} />
    </label>
  </div>
);

export default ToggleSwitch;
