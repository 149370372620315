import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import rootRoutes from '../../routes';
import { store } from '../../state';
import Toast from '../../app/components/Toast';

class Root extends React.Component<any, any> {
  render() {
    return (
      <Provider store={store}>
        <div>
          <div>
            <Router>{rootRoutes}</Router>
          </div>
          <Toast global error />
        </div>
      </Provider>
    );
  }
}

export default Root;
