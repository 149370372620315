import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { format, parseISO } from 'date-fns';
import type { StoreState } from '../../state/types';
import type { Increment } from '../types';
import { selectActiveOrgId } from '../../organization/selectors';
import { selectCreditAccount } from '../selectors';

class IncrementsBox extends Component<{
  increments: Increment[];
}, {
  modalOpen: boolean;
}> {
  state = {
    modalOpen: false,
  };

  handleOpen = () => this.setState({
    modalOpen: true,
  });

  handleClose = () => this.setState({
    modalOpen: false,
  });

  render() {
    const teaserIncrements = this.props.increments.slice(0, 3);
    return (
      <>
        <KpiContainer>
          <h2>Recent top ups</h2>
          <IncrementsContainer>
            <IncrementRow marginBottom>
              <div className="headerCell dateCell">
                Entry Date
              </div>
              <div className="headerCell dateCell">
                Top up Date
              </div>
              <div className="headerCell amountCell">
                Amount
              </div>
            </IncrementRow>
            {teaserIncrements.map(({
              createdAt,
              entryDate,
              amount,
            }, i) => /* @ts-ignore */ (
              <IncrementRow key={`teaser${i}`}>
                <div className="dateCell">
                  {format(parseISO(entryDate), 'dd.MM.yyyy')}
                </div>
                <div className="dateCell">
                  {format(parseISO(createdAt), 'dd.MM.yyyy')}
                </div>
                <div className="amountCell">
                  +
                  {(amount / 100).toLocaleString('fi-FI', {
                    style: 'currency',
                    currency: 'EUR',
                  })}
                </div>
              </IncrementRow>
            ))}
            <KpiEditLink onClick={this.handleOpen}>View all</KpiEditLink>
          </IncrementsContainer>
        </KpiContainer>
        <Modal open={this.state.modalOpen} onClose={this.handleClose} basic size="small">

          <Modal.Content>
            <Container>
              <h2>All top ups</h2>

              <IncrementsContainer>
                <IncrementRow marginBottom>
                  <div className="dateCell">
                    Entry Date
                  </div>
                  <div className="dateCell">
                    Top up Date
                  </div>
                  <div className="amountCell">
                    Amount
                  </div>
                </IncrementRow>
                {this.props.increments.map(({
                  createdAt,
                  entryDate,
                  amount,
                }, i) => (
                  <ModalIncrementRow key={i} marginBottom={false} grey={!(i % 2)}>
                    <div className="dateCell">
                      {format(parseISO(entryDate), 'dd.MM.yyyy')}
                    </div>
                    <div className="dateCell">
                      {format(parseISO(createdAt), 'dd.MM.yyyy')}
                    </div>
                    <div className="amountCell">
                      +
                      {(amount / 100).toLocaleString('fi-FI', {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </div>
                  </ModalIncrementRow>
                ))}

              </IncrementsContainer>

            </Container>
          </Modal.Content>
        </Modal>
      </>
    );
  }
}

export default connect((state: StoreState) => {
  const creditAccount = selectCreditAccount(state, selectActiveOrgId());
  return {
    increments: creditAccount ? creditAccount.increments : [],
  };
})(IncrementsBox);

const Container = styled.div`
  margin: auto;
  background: white;
  border-radius: 12px !important;
  color: black;
  padding: 50px;
  max-width: 550px;
  font-size: 13pt;

  & p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const KpiContainer = styled.div`
  height: 100%;
  text-align: initial;
  background: rgb(245, 245, 245);
  padding: 20px 30px;
  border-radius: 12px;
  border: 2px solid #e4e4e4;
`;

const KpiEditLink = styled.a`
  cursor: pointer;
`;

const IncrementRow = styled.div`
  margin-bottom: ${(props: { marginBottom: boolean; }) => (props.marginBottom ? '13px' : '0')};
  display: flex;

  .dateCell {
    flex: 0 1 auto;
    min-width: 0;
    max-width: 150px;
    width: 150px;
    text-align: left;
  }

  .headerCell {
    font-weight: bold;
  }

  .amountCell {
    flex: 1 1 auto;
    min-width: 0px;
    text-align: right;
    font-weight: bold;
  }
`;

const IncrementsContainer = styled.div`
  padding-top: 10px;
`;

const ModalIncrementRow = styled(IncrementRow)`
  padding: 0 5px;
  & > p {
    margin: 0;
  }
  background: ${(props: { grey: boolean; }) => (props.grey ? '#f3f3f3' : 'inherit')};
`;
