import { RedocStandalone } from 'redoc';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import Navbar from '../../app/components/Navbar';

const ApiDoc = ({
  apiDoc,
}: {
  apiDoc: any;
}) => (
  <Container>
    <Helmet>
      <title>HSL - OpenMaaS Ticket API Documentation</title>
      <meta name="description" content="HSL OpenMaaS Ticket API documention." />
    </Helmet>

    <Navbar />
    <main className="main-apidoc">
      <RedocStandalone
        specUrl={apiDoc}
        options={{
          hideDownloadButton: true,
        }}
      />
    </main>
  </Container>
);

export default ApiDoc;

const Container = styled.div`
  width: 100%;

  & header {
    margin-bottom: 0;
  }
`;
