/* eslint-disable func-names */
import { update } from 'lodash';
import { select, put, call } from 'redux-saga/effects';
import { StoreState } from '../state/types';

type SetStateType = (path: Array<any> | string | number, reducer: (state: any) => any) => any;

const setState: SetStateType = (path, reducer) => {
  const effect = call(function* () {
    const state: StoreState = yield select();
    // Assign a new state object by spreading for immutability.
    const updatedState: StoreState = update({ ...state }, path, reducer);
    yield put({
      type: 'GENERIC/SET_STATE',
      payload: updatedState,
    });
  });
  return effect;
};

export default setState;
