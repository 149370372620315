import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import { selectActiveOrgId } from '../../organization/selectors';

export default connectedAuthWrapper({
  authenticatedSelector: () => {
    const orgId = selectActiveOrgId();
    return !!orgId && orgId !== '';
  },
  wrapperDisplayName: 'HasOrganizationSelected',
});
